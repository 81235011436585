<template>
  <div class="py-4 px-4 sm:px-6 rounded-t-lg bg-transparent dark:text-v3-gray-200 rounded-b-lg">
    <span>
        <span class="flex justify-between">
            <slot name="title">
                Details
            </slot>
            <span v-if="withEditIcon">
                <svg v-if="!editing" @click="$emit('editClicked')" class="text-v3-gray-600 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"/></svg>
                <svg v-if="editing" @click="$emit('saveClicked')" class="text-v3-gray-600 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m20.41 6.41l-2.83-2.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7.4l8.6-8.6V7.83c0-.53-.21-1.04-.59-1.42M12 18c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3m3-9c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h7c.55 0 1 .45 1 1zm4.99 7.25l1.77 1.77l-4.84 4.84c-.1.09-.23.14-.36.14H15.5c-.28 0-.5-.22-.5-.5v-1.06c0-.13.05-.26.15-.35zm3.26.26l-.85.85l-1.77-1.77l.85-.85c.2-.2.51-.2.71 0l1.06 1.06c.2.2.2.52 0 .71"/></svg>
            </span>
        </span>
    </span>

  </div>
</template>
<script>
export default {
  name: 'BlankHeader',
    props:{
      isActive:{
          type: Boolean,
          default: false
      },
        withEditIcon:{
            type: Boolean,
        },
        editing:{
          type: Boolean,
            default: false
        }
    },
    methods:{
    }
}
</script>
