
<template>
    <div v-if="status" class="px-2 rounded-lg p-1 bg-opacity-10"  :class="colourClasses()">{{ status }}</div>
</template>

<script>
export default{
    props:{
        status:{
            type: String,
            default: null
        },
        name:{
            type: String,
            default: null
        }
    },
    methods:{
        colourClasses(){
            let type = this.status;
            if(this.name){
                type = this.name;
            }
            if(type === 'orders-in-progress' || type === 'in-progress'){
                return 'text-v3-orange bg-v3-orange';
            }
            if(type === 'awaiting-approval'){
                return 'text-v3-blue bg-v3-blue';
            }
            if(type === 'schedules' || type === 'denied'){
                return 'text-v3-red bg-v3-red';
            }
            if(type === 'quotes-mismatch'){
                return 'text-v3-yellow bg-v3-yellow';
            }
            if(type === 'approval' || type === 'approved' || type === 'rejected'){
                return 'text-v3-green bg-v3-green';
            }
            if(type === 'pending' || type === 'pending-approval'  || type === 'draft' || type === 'supervisor'){
                return 'text-v3-yellow bg-v3-yellow';
            }
            if(type === 'out-for-quote'){
                return 'bg-v3-purple text-v3-purple';
            }
            if(type === 'quotes-received'){
                return 'bg-v3-yellow text-v3-yellow';
            }
            if(type === 'ready-for-quote'|| this.status === 'new'){
                return 'bg-v3-green text-v3-green';
            }
            if(type === 'awaiting-cms-quote'){
                return 'bg-v3-purple text-v3-purple';
            }
            if(type === 'awaiting-cms-quote-approval'){
                return 'bg-v3-blue text-v3-blue';
            }
            if(type === 'awaiting-sm-approval'){
                return 'bg-v3-blue text-v3-blue';
            }
            if(type === 'awaiting-approval'){
                return 'bg-v3-indigo text-v3-indigo';
            }
            if(type === 'awaiting-senior-approval'){
                return 'bg-v3-indigo text-v3-indigo';
            }
            if(type === 'awaiting-delivery'){
                return 'bg-v3-pink text-v3-pink';
            }
            if(type === 'completed'){
                return 'bg-v3-green text-v3-green';
            }
            if(type === 'ready-for-cms-quote'){
                return 'bg-v3-green text-v3-green';
            }
            if(type === 'partial-delivery'){
                return 'bg-v3-blue text-v3-blue';
            }
            if(type === 'awaiting-invoice-upload'){
                return 'bg-indigo-300 text-indigo-800';
            }
            if(type === 'awaiting-client-approval'){
                return 'bg-v3-yellow text-v3-yellow';
            }
            if(type === 'scheduled' || type === 'archived-for-cms'){
                return 'bg-v3-red text-v3-red';
            }

            if(type === 'awaiting-proforma-payment'){
                return 'bg-v3-purple text-v3-purple';
            }
            if(type === 'ready-to-order'){
                return 'bg-v3-green text-v3-green'
            }
            if(type === 'awaiting-credit'){
                return 'bg-v3-pink text-v3-pink';
            }
            if(type === 'payment-check'){
                return 'bg-v3-purple text-v3-purple';
            }
            if(type === 'Pre-Check Required'){
                return 'bg-v3-green bg-opacity-20 text-v3-green';
            }

            return 'text-v3-purple bg-v3-purple';
        }
    }
}
</script>
