import { render, staticRenderFns } from "./PhotoViewerWithIcon.vue?vue&type=template&id=292e8f64&scoped=true&"
import script from "./PhotoViewerWithIcon.vue?vue&type=script&lang=js&"
export * from "./PhotoViewerWithIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292e8f64",
  null
  
)

export default component.exports