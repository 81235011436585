<template>
    <dashboard-widget-template title="OnSiteSelfie">
        <template v-slot:body>
            <pagination class="mt-2" title="Clock Ins" :entities="checkins" :showTitleBar="false" bodyBgClass="bg-transparent" tableHeaderBgClass="bg-transparent" :allowOverflow="false" :has-entities="checkins && checkins.length> 0">
                <template v-slot:headers>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">User
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Clock In</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Clock Out</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Project</th>
                </template>
                <template v-slot:items>
                    <tr v-for="entity in checkins">
                        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                            <div>{{ entity.user_name }}
                            </div>
                        </td>
                        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                            <div>{{ getTimeClockedIn(entity.clock_in['time']) }}
                            </div>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm">
                            <span v-if="entity.clock_out && entity.clock_out['time']">
                                {{ getTimeClockedIn(entity.clock_out['time']) }}
                            </span>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                        </td>
                    </tr>
                </template>
            </pagination>
        </template>
    </dashboard-widget-template>
</template>

<script>
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import Pagination from "@/v3/components/pagination/Pagination.vue";
import StatusBadge from "@/v3/components/StatusBadge.vue";
import moment from "moment";
export default {
    components: {StatusBadge, Pagination, DashboardWidgetTemplate},
    props:{
        size:{
            type: String,
            default: null
        },
        checkins:{
            type: Object|Array,
            default: null
        }
    },
    methods:{
        getTimeClockedIn(date) {
            if(date) {
                var dateObj = new Date(date)
                return moment(dateObj).format('h:mma')
            }
        },
    }


}
</script>
