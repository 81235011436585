<template>
    <div class="overflow-y-auto shadow rounded-lg h-full notifications-widget bg-v3-base-500 dark:bg-v3-gray-900">
        <div class="shadow sm:rounded-md">
            <p class="ml-5 text-sm leading-5 font-medium text-gray-500 pt-4">
                Notifications
            </p>
            <template v-if="total === 0">
                <p class="ml-5 text-sm leading-5 font-medium text-gray-500 pb-4 pt-3">
                    There are no notifications.
                </p>
            </template>
            <ul class="divide-y divide-gray-200 text-v3-gray-500 dark:text-v3-gray-400" v-else>
                <li v-for="notification in notifications">
                    <a href="#" class="block hover:bg-v3-gray-100 dark:hover:bg-v3-gray-800">
                        <div class="px-4 py-4 sm:px-6">
                            <div class="flex items-center justify-between">
                                <p class="text-sm font-medium text-indigo-600 truncate flex items-center">
                                    <template v-if="!notification.entity.properties.get('is_read')">

                                    <span
                                          aria-hidden="true"
                                          class="h-3 w-3 bg-red-400 rounded-full mr-2 flex-shrink-0 " style="z-index: 2;">
                                        <span  class="h-2 w-2 rounded-full bg-red-400"></span>
                                    </span>
                                        <span class="font-bold">
                                            {{notification.entity.properties.get('title')}}
                                        </span>
                                    </template>
                                    <template v-else>
                                        {{notification.entity.properties.get('title')}}
                                    </template>

                                </p>
                                <div class="ml-2 flex-shrink-0 flex">
                                    <confirmation-action
                                        v-if="!notification.entity.properties.get('is_read')"
                                        :action="notification.entity.actions.first()"
                                        :action-key="notification.entity.properties.get('id')"
                                        onSuccessPath="/"></confirmation-action>
                                    <span class="pr-5" v-else>
                                                <span class="text-sm">read </span>
                                                <span class="text-xs text-gray-500 group-hover:text-gray-900 font-medium truncate">{{ notification.entity.properties.get('read_at_human')
                                                    }}</span>
                                            </span>
                                </div>
                            </div>
                            <div class="mt-2 sm:flex sm:justify-between">
                                <div class="sm:flex">
                                    <p class="flex items-center text-sm ">
                                        <span v-html="notification.entity.properties.get('content')"></span>
                                    </p>
                                </div>

                            </div>
                            <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <p>
                                {{notification.entity.properties.get('created_at')}}
                            </p>
                        </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>


    import Siren from "super-siren";
    import {mapGetters} from "vuex";
    import ConfirmationAction from "@/v2/components/ConfirmationAction";

    export default {
        components: {
            ConfirmationAction,
        },
        data() {
            return {
                notifications: null,
                total: 0
            }
        },
        props:{
            mode:{
                type: String,
                default: null
            }
        },
        created() {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.Client.removeHeader('Cache-Control');
            Siren.Client.addHeader('Cache-Control', 'max-age=7200');

            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("notifications")
                    )
                    .then(res => {
                        let siren = res.body;
                        this.notifications = siren.entities.filter(entity => entity.rels.contains('notification'))
                        this.total = siren.properties.get('total')
                    }, this);
            })
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    };
</script>

<style lang="scss">
    .notifications-widget a a{
        text-decoration: underline;
    }
</style>

