<template>
    <div id="container" class="px-4 mb-4 block">
        <dashboard :id="'dashExample'" class="hidden md:block">
            <dash-layout class="mt-4" v-for="layout in dlayouts" v-bind="layout" :debug="false" :key="layout.breakpoint">
                <dash-item class="dash-items" v-for="(item, index) in layout.items" v-bind.sync="item" :key="item.id" v-on:moveEnd="widgetsChanged=true" v-on:resizeEnd="widgetsChanged=true" >
                    <template v-if="item.type">
                        <component :is="item.type" :mode="mode">
                        </component>
                                <button v-if="editable" class="absolute top-0 right-0" @click="deleteWidget(index)">
                                    <svg class="h-5 w-5 text-gray-300 hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                    </template>
                </dash-item>
            </dash-layout>
        </dashboard>
        <dashboard :id="'dashExample'" class="block md:hidden">
            <template class="mt-4" v-for="layout in dlayouts" v-bind="layout">
                <template class="dash-items" v-for="(item, index) in layout.items" v-bind.sync="item">
                    <template v-if="item.type">
                        <component :is="item.type" class="mt-4" :mode="mode">
                        </component>
                    </template>
                </template>
            </template>
        </dashboard>
        <div v-if="widgetsChanged"
             style="right:0px;left:16rem;"
             class="p-3 pr-6 fixed bottom-0 bg-white mt-6 shadow-sm -mt-4 bg-white flex justify-end border border-b-0 border-x-0 border-gray-200 z-10">
            <widgets-action-with-form v-if="!saveItemsAction.fields.isEmpty()" :action="saveItemsAction"
                              actionKey="'add-default-widget"
                              :redirectOnSuccess="true"
                              :items="dlayouts[0].items"
                              onSuccessPath="/dashboard-widgets"
            ></widgets-action-with-form>

            <primary-button v-else @click.native="saveItems()" class="cursor-pointer" data-cy="save" :loading="loading">Save
                Widgets
            </primary-button>
        </div>
    </div>

</template>

<script>
    import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
    import Siren from "super-siren";
    import { mapGetters } from "vuex";
    import ProjectLocations from "./Dashboard/ProjectLocations";
    import PrimaryButton from "./PrimaryButton";
    import OrdersInProgress from "./Widgets/OrdersInProgress";
    import AwaitingApproval from "./Widgets/AwaitingApproval";
    import Schedules from "./Widgets/Schedules";
    import DashboardClockedIn from "./Dashboard/DashboardClockedIn";
    import CurrentDateTime from "./Widgets/CurrentDateTime";
    import WidgetsActionWithForm from './WidgetsActionWithForm';
    import Notifications from "./Widgets/Notifications";
    import AwaitingCredit from "@/components/Widgets/AwaitingCredit";
    import QuotesMismatch from "@/components/Widgets/QuotesMismatch";
    import AllStats from "@/components/Widgets/AllStats";

    export default {
        components: {
            Dashboard,
            DashLayout,
            DashItem,
            PrimaryButton,
            ProjectLocations,
            OrdersInProgress,
            AwaitingApproval,
            Schedules,
            DashboardClockedIn,
            CurrentDateTime,
            WidgetsActionWithForm,
            Notifications,
            AwaitingCredit,
            QuotesMismatch,
            'stats': AllStats
        },
        data() {
            return {
                dlayouts: [
                    {
                        breakpoint: "xl",
                        numberOfCols: 20,
                        items: []
                    }
                ],
                saveItemsAction: null,
                widgetsChanged: false,
                loading:false,
                backgroundImage: null
            };
        },
        props: {
            default: {
                type: Boolean,
                default: false
            },
            widget_id: {
                type: Number,
                default: null
            },
            response:{
                type: Object
            },
            editable:{
                type: Boolean
            },
            mode:{
                type: String,
                default: null
            }
        },
        methods: {
            async saveItems(){
                this.loading = true;
                let temp = this.dlayouts[0].items;

                this.saveItemsAction.perform({
                    items: JSON.stringify(this.dlayouts[0].items)
                }).then(res => {
                    this.$store.dispatch(
                        "setMessage",
                        'The widget was saved successfully'
                    );
                    this.widgetsChanged = false
                    this.editable = false;
                    EventBus.$emit('disableEditWidgets');
                    this.loading = false;
                })
                    .catch(error => {
                        if (error.response.status !== 422) {
                            alert('There was an error processing your request.' + error);
                        }
                        this.errors.clear();
                        this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.response.text));
                        this.loading = false;
                        this.editable = false;
                        EventBus.$emit('disableEditWidgets');
                    });
            },
            deleteWidget(index){
                this.dlayouts[0].items.splice(index, 1);
                this.widgetsChanged = true;
            },
            createExampleWidgets(){
                this.dlayouts[0].items = [
                    { id: "1", x: 0, y: 0, width: 4, height: 2, type: 'orders-in-progress' },
                    { id: "2", x: 4, y: 0, width: 4, height: 2, type: 'awaiting-approval' },
                    { id: "3", x: 8, y: 0, width: 4, height: 2, type: 'schedules' },
                    { id: "4", x: 0, y: 4, width: 6, height: 6, type: 'project-locations' },
                ]
                this.areWidgetsEditable();
            },
            areWidgetsEditable(){
                let editable = this.editable
                this.dlayouts[0].items.forEach(function(item){
                    item.draggable = editable;
                    item.resizable = editable;
                });
            },
            enableWidgets(){
                this.editable = true;
                this.areWidgetsEditable();
            }
        },
        created() {
            let follow = Siren.Helper.follow;

            if(!this.default) {
                Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                    this.saveItemsAction = response.body.findActionByName('save-widgets');
                    Siren.get(process.env.VUE_APP_API_URL + "/api/")
                        .then(
                            follow("current-user")
                        )
                        .then(res => {
                            let siren = res.body;
                            let dashboardWidgets = JSON.parse(res.body.properties.get('dashboard_widgets'));
                            if (dashboardWidgets) {
                                this.dlayouts[0].items = dashboardWidgets;
                                this.areWidgetsEditable();
                            }else{
                                this.createExampleWidgets();
                             }
                            this.dlayouts[0].items.forEach((value, index) => {
                                let hasOssModule = res.body.properties.get('has_oss_module');
                                let isDirectSupplier = res.body.properties.get('is_direct_supplier');
                                if(value['type'] === 'dashboard-clocked-in' && !hasOssModule){
                                    this.dlayouts[0].items.splice(index,1);
                                }
                                if(value['type'] === 'project-locations' && isDirectSupplier){
                                    this.dlayouts[0].items.splice(index, 1);
                                }
                            });
                        }, this);
                })
            }else if(this.response.body){
                this.saveItemsAction = this.response.body.findActionByName('update-widget');
                let widgets = JSON.parse(this.response.body.properties.get('items'));
                if(widgets){
                    this.dlayouts[0].items = widgets;
                    this.areWidgetsEditable();
                }
            }
            else{
                Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {

                    Siren.get(process.env.VUE_APP_API_URL + "/api/")
                        .then(
                            follow("admin.dashboard-widgets")
                        )
                        .then(res => {
                            this.saveItemsAction = res.body.findActionByName('add-default-widget');
                            let siren = res.body;
                        }, this);
                })
                this.createExampleWidgets();
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
        },
        mounted(){
            EventBus.$on('addWidget', function(params) {
                let widgets = this.dlayouts[0].items;
                let max = 0;
                let maxX = 0;
                let maxY = 0;
                widgets.forEach(widget => {
                    if (widget.id > max) {
                        max = widget.id;
                    }
                    if (widget.x > maxX) {
                        maxX = widget.x;
                    }
                    if (widget.y + widget.height > maxY) {
                        maxY = widget.y + widget.height;
                    }
                });
                widgets.push({id: parseInt(max) + 1, x: 0, y: maxY, width: 2, height: 2, type: params});
                this.widgetsChanged = true;
            }.bind(this))

            EventBus.$on('enableEditWidgets', function() {
                this.enableWidgets();
            }.bind(this))
            EventBus.$on('disableEditWidgets', function() {
                this.editable = false;
                this.areWidgetsEditable();
            }.bind(this))
        }
    };
</script>

<style>
    .content {
        height: 100%;
        width: 100%;
        border: 2px solid #42b983;
        border-radius: 5px;
    }
</style>

<style scoped>

</style>
