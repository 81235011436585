<template>
    <widget-stat type="all" :mode="mode"></widget-stat>
</template>

<script>


export default {
    components: {
        WidgetStat: () => import('./WidgetStat.vue')
    },
    props:{
        mode:{
            type: String,
            default: null
        }
    }
};
</script>
