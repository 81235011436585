<template>
    <div class="dark:text-white text-v3-gray-800 flex flex-col h-full pl-4 pt-2">
        <p class="w-full p-2 font-bold text-xl dark:text-white text-v3-gray-800">
            {{ title }}
        </p>
        <slot name="body"></slot>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: null
        }
    }
}
</script>
