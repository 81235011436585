<template>
  <div class="py-4 px-4 sm:px-6 rounded-t-lg text-v3-gray-900 dark:text-v3-gray-200"
       :class="{'rounded-b-lg': collapsable && !open, 'cursor-pointer': collapsable, [headerColourClass]: headerColourClass}" @click="toggleOpen">
    <span :class="{'flex items-center': collapsable}">
        <template v-if="collapsable">
            <span
                class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400"
                v-if="!open">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                     viewBox="0 0 24 24"><path fill="currentColor"
                                               d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/></svg>
            </span>
            <span v-else
                  class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                     viewBox="0 0 24 24"><path fill="currentColor"
                                               d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z"/></svg>
            </span>
        </template>
        <span>
            <slot name="title">
                {{ title }}
            </slot>
        </span>
    </span>

  </div>
</template>
<script>
export default {
  name: 'collapsable-header',
    props:{
      collapsable:{
          type: Boolean,
          default: true
      },
        open:{
          type: Boolean,
            default: false
        },
        headerColourClass:{
            type: String,
            default: 'bg-v3-gray-50 bg-opacity-10'
        },
        title:{
          type: String,
            default: 'Details'
        }
    },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
