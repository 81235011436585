<template>
    <div>
        <label :for="field.name" class="block text-sm font-medium leading-5 text-gray-700" v-if="showLabel">{{field.title}}<span class="text-red-500 ml-1" v-if="required">*</span></label>
        <div class="relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm sm:leading-5">
                  <template v-if="currencyType === 'EUR'">
                      €
                  </template>
                  <template v-else>
                      £
                  </template>
              </span>
            </div>

            <input :id="field.name" class="form-input shadow-sm  min-w-27 block w-full pl-7 pr-12 sm:text-sm sm:leading-5 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency"
               :name="field.name"
               :placeholder="field.title"
               :value="value/100"
               type="text"
               @change="change"
               @blur="blur" @input="input"
               ref="input"
                   data-lpignore="true"
            />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm sm:leading-5" id="price-currency">
                {{currencyType}}
              </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {},
            field: {
                type: Object
            },
            currencyType: {
                type: String,
                default: 'GBP'
            },
            showLabel:{
                type: Boolean,
                default: true
            }
        },
        methods: {
            change() {
                this.$emit("change");
            },
            input(event) {

            },
            blur(event) {
                this.$emit("input", event.target.value * 100);
                this.$emit("blur");
            },
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
