<template>
        <div v-if="items" class="overflow-hidden shadow rounded-lg min-h-full flex flex-col justify-between h-full bg-v3-base-500 dark:bg-v3-gray-900">
          <div class="px-4 flex-grow flex justify-start items-center">
            <div class="flex items-center pt-4">
                <div class="flex-shrink-0 rounded-full p-3 my-3" :class="backgroundColor" v-html="svg"></div>
              <div class="ml-5 flex-grow">
                <dl>
                  <dt
                    class="text-sm leading-5 font-medium text-secondary-darkblue font-bold"
                  >Your<br> order stats</dt>
                </dl>
              </div>
            </div>
          </div>
            <div class="grid grid-cols-3 px-4 py-2 pb-6 sm:px-6 text-secondary-darkgrey">
                <div>
                    <div class="uppercase text-sm">Processing</div>
                    <span class="font-extrabold text-lg">{{getStatDetails('orders-in-progress').entity.properties.get('value')}}</span>
                </div>
                <div>
                    <div class="uppercase text-sm">Schedules</div>
                    <span class="font-extrabold text-lg">{{getStatDetails('schedules').entity.properties.get('value')}}</span>
                </div>
                <div>
                    <div class="uppercase text-sm">Approval</div>
                    <span class="font-extrabold text-lg">{{getStatDetails('awaiting-approval').entity.properties.get('value')}}</span>
                </div>

            </div>
        </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
            },
            title: {
                type: String,
            },
            value: {
                type: String,
            },
            viewMoreLink: {
                type: String,
            },
            viewMoreLinkTitle: {
                type: String,
            },
            items:{
                type: Object
            },
            mode:{
                type: String,
                default: null
            }
        },
        methods: {
          navigateTo(link){
            window.location = this.getFrontendRouteFromApiRoute(link);
          },
            getStatDetails(statName){
                return this.items.filter(item => item.entity.properties.get('name') === statName).first()
            }
        },
        computed: {
            svg() {
                return `
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                `;
            },
            backgroundColor(){
                return 'bg-secondary-lavenderblue';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
