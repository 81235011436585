<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label v-if="showLabel" for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3">{{field.title}}<span class="text-v3-red ml-1" v-if="required">*</span></label>
            <input
            :name="field.name"
            class="form-input block w-full rounded-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 border-0 py-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70"
            :placeholder="field.title"
            v-model="propsVal"
            :disabled="disabled"
            type="number"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value:{
                type:String
            },
            field: {
                type: Object
            },
            disabled: {
                type: Boolean,
                default:false
            },
            showLabel: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
