<template>
    <dashboard-widget-template title="Procure">
        <template v-slot:body>
            <div :class="containerClasses()">
                <template v-for="(stat, index) in stats">
                    <div @click="navigateTo(stat)" v-if="getAmountForLength(index)" class="flex flex-col items-center justify-center cursor-pointer">
                        <span class="text-6xl p-6">{{stat.entity.properties.get('value')}}</span>
                        <status-badge :status="stat.entity.properties.get('title')" :name="stat.entity.properties.get('name')"></status-badge>
                    </div>
                </template>
            </div>
        </template>

    </dashboard-widget-template>
</template>

<script>
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import StatusBadge from "@/v3/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge,
        DashboardWidgetTemplate
    },
    props:{
        size:{
            type: String,
            default: 'small'
        },
        stats:{
            type: Object,
            default: null
        },
        startFrom:{
            type: Number,
            default: 0
        }
    },
    methods:{
        getAmountForLength(index){
            if(this.size === 'small'){
                return index === this.startFrom;
            }
            if(this.size === 'medium-long'){
                return index < 3;
            }
            if(this.size === 'long' || this.size === 'medium-large'){
                return index < 6;
            }
            if(this.size === 'large'){
                return index < 9;
            }
            return true;
        },
        containerClasses(){
            if(this.size === 'large' || this.size === 'medium-large'){
                return 'grid grid-cols-3 h-full w-full items-center';
            }
            return 'flex justify-around items-center center h-full w-full';
        },
        navigateTo(stat){
            let link = stat.entity.links.filter(link => link.rels.contains("view-more")).first().href
            window.location = this.getFrontendRouteFromApiRoute(link);
        }
    }
}
</script>
