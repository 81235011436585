<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-20">
            <label for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3" v-if="showLabel">{{field.title}}<span v-if="isOptional" class="text-v3-gray-900 text-opacity-50 dark:text-white dark:text-opacity-50"> (Optional)</span><span class="text-v3-red ml-1" v-if="required">*</span></label>
            <div>
                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                <button type="button" @click="toggleValue" class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2" role="switch" aria-checked="false"
                :class="{'bg-v3-green': propsVal === 1 || propsVal === '1', 'bg-v3-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5': propsVal !== 1 && propsVal !== '1'}">
                    <span class="sr-only">Use setting</span>
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span aria-hidden="true" class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    :class="{'translate-x-5': propsVal === 1 || propsVal === '1', 'translate-x-0': propsVal !== 1 && propsVal !== '1'}"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {},
            field: {
                type: Object
            },
            validationFieldName: {
                type: String,
                default: 'random'
            },
            dependsOn: {
                type: String,
                default: null
            },
            dependsOnValue: {
                default: null
            },
            isOptional: {
                type: Boolean,
                default: false
            },
            dependsOn2: {
                type: String,
                default: null
            },
            dependsOnValue2: {
                default: null
            },
            includeNullOption:{
                type: Boolean,
                default: true
            },
            showLabel:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                propsVal: this.value,
                selectedOptions: null
            }
        },
        computed: {
            filteredOptions() {
                if (this.dependsOn !== null && this.dependsOnValue === null) {
                    return;
                }

                if (this.dependsOn === null || this.dependsOnValue === null) {
                    return this.field.options;
                }
                let options = this.field.options;
                options = options.filter(function (option) {
                    return option[this.dependsOn] !== null && (option[this.dependsOn]).toString() === (this.dependsOnValue).toString() ||
                        (Array.isArray(option[this.dependsOn]) && ((option[this.dependsOn]).includes(parseInt(this.dependsOnValue)) ||
                            this.checkOptionsContainsDependsArrayValue(option[this.dependsOn], this.dependsOnValue)
                            ))
                    || this.dependsOnValue.toString().split(',').includes((option[this.dependsOn]).toString())


                }.bind(this));
                if(this.dependsOn2 === null || this.dependsOnValue2 === null){
                    return options;
                }

                options = options.filter(function (option) {
                    return option[this.dependsOn2] !== null && (option[this.dependsOn2]).toString() === (this.dependsOnValue2).toString() ||
                        (Array.isArray(option[this.dependsOn2]) && ((option[this.dependsOn2]).includes(parseInt(this.dependsOnValue2)) ||
                            this.checkOptionsContainsDependsArrayValue(option[this.dependsOn2], this.dependsOnValue2)
                        ))
                        || this.dependsOnValue2.toString().split(',').includes((option[this.dependsOn2]).toString())


                }.bind(this));

                return options;

            },
            isDisabled() {
                return this.dependsOn !== null && this.dependsOnValue === null;
            },
            required: function(){
                if(this.field.validation !== null && this.field.validation !== undefined) {
                    return this.field.validation.includes('required');
                }
                return false;
            },
        },
        methods:{
            checkOptionsContainsDependsArrayValue(dependsOnOption, dependsOnValue){
                let bool = false;
               dependsOnOption.forEach(function(value){
                    if( dependsOnValue.split(',').includes(value.toString())){
                        bool = true;
                    }
                }.bind(this))
                return bool;
            },
            onChange(event) {
                this.$emit('returnPaymentType', event.target.options[event.target.options.selectedIndex].dataset.extradetails);
            },
            setValue(value){
                this.propsVal = value;
            },
            toggleValue(){
                this.propsVal = this.propsVal === 1 ? 0: 1;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
            value: {
                handler(value) {
                    this.propsVal = value;
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
