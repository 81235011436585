<template>
  <div v-if="message && !hideNotification" class="fixed z-40 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <transition
    enter-active-class="transform ease-out duration-300 transition"
    leave-active-class="transition ease-in duration-100"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
>
      <div v-show="message && !hideNotification" class="max-w-sm w-full bg-v3-white-100 dark:bg-v3-gray-900 shadow-lg rounded-lg pointer-events-auto">
        <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                  <svg class="w-6 h-6 text-v3-red" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-v3-gray-800 dark:text-v3-gray-200">
                  Failed!
                </p>
                <p class="mt-1 text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                  {{message}}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="hideNotifications()" class="inline-flex text-v3-text-gray-50 text-opacity-50 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "Notification",
  props: ["message", "response"],
  data() {
    return {
      hideNotification: false,
    };
  },
  methods: {
    hideNotifications(){
      this.hideNotification = true;
      this.$store.dispatch(
          "clearMessagesWithoutTimeoutNow"
        );
    }
  },
  watch: {
    message: function(val) {
        this.hideNotification = false;
    },
    response: function(val) {
        this.hideNotification = false;
    }
  }
};
</script>
