<template>
    <div class="mx-2">
        <div>
        <h2 class="mt-2 ml-2 text-v3-gray-800 dark:text-v3-gray-200">
            Notifications
        </h2>
        <div v-if="total === 0" class="text-v3-gray-800 dark:text-v3-gray-200">
            No notifications found
        </div>
        <template v-else>
            <div class="text-sm notifications-widget" v-for="(notification, index) in notifications">
                <div class="my-2">

                    <div class="p-1 bg-v3-gray-250 text-v3-gray-800 dark:bg-v3-base-700 dark:text-v3-gray-200 rounded-xl text-center flex justify-between" :class="{'rounded-b-none rounded-t-xl': notisOpen.includes(index)}">
                        <span class="inline-flex mx-4">{{notification.entity.properties.get('title')}}</span>
                        <confirmation-action additional-button-classes=""
                                             :action="notification.entity.actions.filter(action => action.name === 'read-notification').first()"
                                             action-key="read-notification"
                                             :redirect-on-success="false"
                                             @success="readSuccess">
                            <template v-slot:button>
                                <svg class="h-4 w-4 text-white bg-red-600 rounded-full" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m17.705 7.705l-1.41-1.41L12 10.59L7.705 6.295l-1.41 1.41L10.59 12l-4.295 4.295l1.41 1.41L12 13.41l4.295 4.295l1.41-1.41L13.41 12z"/></svg>
                            </template>
                        </confirmation-action>
                    </div>
<!--                    <div v-if="notisOpen.includes(index)" class="bg-v3-base-200 rounded-b-xl text-xs px-4 text-v3-custom dark:bg-v3-gray-150" v-html="notification.entity.properties.get('content')"></div>-->
                </div>
            </div>
        </template>
        </div>
    </div>
</template>

<script>
import Siren from "super-siren";
import {mapGetters} from "vuex";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";

export default{
    components: {ConfirmationAction},
    data() {
        return {
            notifications: null,
            total: 0,
            notisOpen: []
        }
    },
    props:{
        notificationsFromParent:{
            type: Object,
            default: null
        },
        notificationsTotalFromParent:{
            type: Number,
            default: null
        }
    },
    created() {
        if(this.notificationsFromParent && this.notificationsTotalFromParent){
            this.notifications = this.notificationsFromParent;
            this.total = this.notificationsTotalFromParent;
        }else {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.Client.removeHeader('Cache-Control');
            Siren.Client.addHeader('Cache-Control', 'max-age=7200');

            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(
                    follow("notifications_without_read")
                )
                .then(res => {
                    let siren = res.body;
                    this.notifications = siren.entities.filter(entity => entity.rels.contains('notification') && !entity.entity.properties.get('is_read'))
                    this.total = siren.properties.get('total')
                }, this);
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken"
        })
    },
    methods:{
        toggleNoti(index){
            if(this.notisOpen.includes(index)){
                this.notisOpen.splice(this.notisOpen.indexOf(index), 1);
            }else{
                this.notisOpen.push(index);
            }
        },
        readSuccess(){
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.Client.removeHeader('Cache-Control');
            Siren.Client.addHeader('Cache-Control', 'max-age=7200');
            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("notifications_without_read")
                    )
                    .then(res => {
                        let siren = res.body;
                        this.notifications = siren.entities.filter(entity => entity.rels.contains('notification') && !entity.entity.properties.get('is_read'))
                        this.total = siren.properties.get('total')
                    }, this);
            });
        }
    }
}
</script>
<style>
.notifications-widget a{
    text-decoration: underline;
}
</style>
