<template>
    <button type="button" :class="getColourClass" class="justify-center inline-flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md focus:outline-none  transition ease-in-out duration-150">
        <slot></slot>

        <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4"></loading>
    </button>
</template>

<script>
import Loading from "@/components/Loading";
export default {
    name: "CustomButton",
    components: {
      Loading
    },
    props:{
        loading: {
            type: Boolean,
            default: false
        },
        colourType:{
            type: String,
            default: 'primary'
        }
    },
    computed: {
        getColourClass(){
            if(this.colourType === 'primary'){
                return 'text-white bg-v3-blue';
                // return 'text-white bg-primary-blue hover:bg-white hover:text-primary-blue hover:border-primary-blue border-2 border-primary-blue';
            }else if(this.colourType === 'secondary'){
                return 'text-v3-gray-800 bg-v3-gray-50 bg-opacity-50 dark:text-v3-gray-200';

                return 'text-secondary-darkblue bg-secondary-verylightpeach hover:bg-white hover:text-secondary-darkblue hover:border-secondary-verylightpeach border-2 border-secondary-verylightpeach';
            }else if(this.colourType === 'tertiary'){

                return 'text-v3-gray-800 bg-v3-gray-50 bg-opacity-50 dark:text-v3-gray-200';
                // return 'text-secondary-darkgrey bg-secondary-lightbackb';
            }else if(this.colourType === 'negative'){
                return 'text-white bg-v3-red';
                return 'text-white bg-error hover:bg-white hover:text-error border-error border-2';
            }else if(this.colourType === 'positive'){
                return 'text-white bg-v3-green';
              return 'text-white bg-green-500 hover:bg-white hover:text-green-500 border-green-500 border-2';
            }
        }
    }
}
</script>

<style scoped>

</style>
