<template>
  <div v-if="message && !hideNotification" class="fixed z-50 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <transition
    enter-active-class="transform ease-out duration-300 transition"
    leave-active-class="transition ease-in duration-100"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
>
      <div v-show="message && !hideNotification" class="max-w-sm w-full bg-v3-white-100 dark:bg-v3-gray-900 shadow-lg rounded-lg pointer-events-auto">
        <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                  <svg class="w-6 h-6 text-v3-green" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.64102 16.1996L5.44102 11.9996L4.04102 13.3996L9.64102 18.9996L21.641 6.99961L20.241 5.59961L9.64102 16.1996Z" fill="#14AE5C"/>
                  </svg>

              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-v3-gray-800 dark:text-v3-gray-200">
                  Success!
                </p>
                <p class="mt-1 text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                  {{message}}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="hideNotifications()" class="inline-flex text-v3-gray-50 text-opacity-50 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "Notification",
  props: ["message", "response"],
  data() {
    return {
      hideNotification: false,
      timeouts: [],
      hideDelay: 5000
    };
  },
  created() {
    this.timeouts.push(
      setTimeout(() => {
        this.hideNotifications();
      }, this.hideDelay)
    );
  },
  methods: {
    clearTimeouts() {
      for (var i = 0; i < this.timeouts.length; i++) {
        clearTimeout(this.timeouts[i]);
      }

      this.hideNotification = false;
      this.timeouts.push(
        setTimeout(() => {
          this.hideNotifications();
        }, this.hideDelay)
      );
    },
    hideNotifications(){
      this.hideNotification = true;
      this.$store.dispatch(
          "clearMessagesNow"
        );
    }
  },
  watch: {
    message: function(val) {
      this.clearTimeouts();
    },
    response: function(val) {
      this.clearTimeouts();
    }
  }
};
</script>
