<template>
    <div class="rounded-md flex-grow whitespace-normal">
        <div class="focus-within:z-10">
            <label v-if="showLabel" for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white  mt-3">{{field.title}}<span class="text-v3-red ml-1" v-if="required">*</span></label>
            <date-picker
                         class="form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md p-0"
                         input-class="w-full h-full p-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70"
                         calendar-class="dark:bg-secondary-appgray dark:text-v3-gray-200"
                         v-model="value"
                         :format="customFormatter"
                         placeholder="dd/mm/yyyy"
                         :highlighted="highlighted"
            ></date-picker>
        </div>
    </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import dayjs from "dayjs";
import {now} from "moment";
    export default {
        components:{
            DatePicker
        },
        props: {
            value:{
                type:String
            },
            field: {
                type: Object
            },
            showLabel:{
                type: Boolean,
                default: true
            }
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            },
            highlighted:function() {
                return {
                    dates: [
                        new Date(),
                    ]
                }
            }
        },
        methods:{
            now() {
                return now
            },
            customFormatter(date) {
                this.dateOfBirth =  dayjs(date).format('YYYY-MM-DD')
                return dayjs(date).format('DD/MM/YYYY')
            }
        },
        watch: {
            value: {
                handler(value) {
                this.$emit("input", dayjs(value).format('YYYY-MM-DD'));
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
