<template>
  <div class="flow-root h-full">
    <div class="h-full" :class="{'overflow-auto': hasStickyHeader, 'overflow-x-auto': allowOverflow}">
      <div class="inline-block w-full align-middle">
        <div v-if="showTitleBar" class="px-4 sm:px-6 rounded-t-lg text-v3-gray-800 dark:text-v3-gray-200" :class="{'rounded-b-lg': collapsable && !open, 'cursor-pointer': collapsable, [headerColourClass]: headerColourClass, 'py-3': hasLogoTitle, 'py-4': !hasLogoTitle}" @click="toggleOpen">
            <span class="flex justify-between">
                <span class="flex items-center w-full">
                    <template v-if="collapsable">
                        <span class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400" v-if="!open">
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/></svg>
                        </span>
                        <span v-else class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400">
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z"/></svg>
                        </span>
                    </template>
                    <span class="w-full font-bold">
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </span>
                </span>
            </span>

        </div>
          <div v-if="response">
                <order-tabs :response="response" :activeClass="filtersActiveClass"></order-tabs>
          </div>


          <div v-if="!collapsable || open" class="text-v3-gray-800 dark:text-v3-gray-200 sticky left-0 right-0 bg-v3-gray-50 bg-opacity-20 dark:bg-white dark:bg-opacity-10"
               :class="{'rounded-t-lg': headerColourClass === 'bg-transparent'}">
              <span class="flex justify-end">
                  <div class="flex">
                      <div v-if="saveFilterAction">
                            <form-action class="mx-2" :action="saveFilterAction" :action-key="saveFilterAction.name" title="Save Search" :on-success-path="$route.path"></form-action>
                        </div>
                      <template v-if="response && response.body">
                        <template v-for="actionWithFields in response.body.actions.filter(action => action.name === 'set-as-default' || action.name === 'delete-saved-filter')">
                            <form-action v-if="!actionWithFields[1].fields.isEmpty() && actionWithFields[1].fields.filter(field => field.type !== 'hidden').first()" class="action-with-fields mx-2" :action="actionWithFields[1]"
                                         :actionKey="actionWithFields[0]"
                                         :redirectOnSuccess="true"
                                         :onSuccessPath="$router.currentRoute.fullPath"
                            ></form-action>
                            <confirmation-action v-else class="action-with-fields mx-2" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                            ></confirmation-action>
                        </template>
                          </template>
                        <slot name="additional-buttons">

                        </slot>
                  </div>
              </span>
            <span class="flex justify-between">
                <span>
                    <slot name="filters" v-if="filterAction">
                        <form @submit.prevent="performAction(filterAction)">
                            <div class="rounded-md flex-grow">
                                <div class="focus-within:z-20">
                                    <div class="grid gap-1 items-center m-2 ml-4 sm:ml-6" :class="{'grid-cols-3 lg:grid-cols-6': !detailsOpen, 'grid-cols-1': detailsOpen}">
                                            <template v-for="filter in filterAction.fields.filter(field => field.type !== 'hidden')">
                                                <div class="rounded-md" v-if="filter[0]">
                                                    <div class="focus-within:z-10">
                                                            <property-or-field v-if="filter[0] && (filter[0] === 'filter[client_id]' || filter[0] === 'organisation_id')"
                                                            :fieldId="filter[0]"
                                                            :action="filterAction"
                                                            :showLabel="false"
                                                            v-model="clientId"
                                                            :depends-on-value="getDependsOnValue(filter[0])"
                                                            :depends-on="getDependsOn(filter[0])">
                                                            </property-or-field>
                                                        <property-or-field v-else-if="filter[0] && filter[0] === 'filter[project_id]'"
                                                                           :fieldId="filter[0]"
                                                                           :action="filterAction"
                                                                           :showLabel="false"
                                                                           v-model="projectId"
                                                                           :depends-on-value="getDependsOnValue(filter[0])"
                                                                           :depends-on="getDependsOn(filter[0])">
                                                            </property-or-field>
                                                        <property-or-field v-else
                                                                       :fieldId="filter[0]"
                                                                       :action="filterAction"
                                                                       :showLabel="false"
                                                                       v-model="filterActionData[filter[0]]"
                                                                       :depends-on-value="getDependsOnValue(filter[0])"
                                                                       :depends-on="getDependsOn(filter[0])">
                                                        </property-or-field>
                                                    </div>
                                                </div>
                                            </template>

                                        <div class="flex items-center">
                                            <custom-button type="submit" @click.native="performAction(filterAction)" colourType="tertiary" class="m-1">
                                                Apply Filter
                                            </custom-button>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </form>

                    </slot>
                </span>
                <div class="flex">

                    <span v-if="withEditIcon || withAltViewIcon || withDeleteIcon" class="flex items-center m-2">
                        <span v-if="withEditIcon">
                            <svg title="edit" v-if="!editing" @click="$emit('editClicked')" class="text-v3-gray-600 dark:text-v3-base-300 h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"/></svg>
                            <svg title="save" v-if="editing" @click="$emit('saveClicked')" class="text-v3-gray-600 dark:text-v3-base-300 h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m20.41 6.41l-2.83-2.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7.4l8.6-8.6V7.83c0-.53-.21-1.04-.59-1.42M12 18c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3m3-9c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h7c.55 0 1 .45 1 1zm4.99 7.25l1.77 1.77l-4.84 4.84c-.1.09-.23.14-.36.14H15.5c-.28 0-.5-.22-.5-.5v-1.06c0-.13.05-.26.15-.35zm3.26.26l-.85.85l-1.77-1.77l.85-.85c.2-.2.51-.2.71 0l1.06 1.06c.2.2.2.52 0 .71"/></svg>
                        </span>
                        <span v-if="withAltViewIcon && detailsOpen" class="items-center">
                            <a title="alt view" @click="$emit('altViewClicked')">
                                <svg class="text-v3-gray-600 dark:text-v3-base-300 h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 15h8v-2H3zm0 4h8v-2H3zm0-8h8V9H3zm0-6v2h8V5zm10 0h8v14h-8z"/></svg>
                            </a>
                        </span>
                        <span v-if="withDeleteIcon" class="items-center">
                            <a title="delete" @click="$emit('deleteClicked')">
                                <svg class="text-v3-gray-600 dark:text-v3-base-300 fill-current h-6 w-6 cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="currentColor"/>
                                </svg>

                            </a>
                        </span>
                    </span>
                </div>
            </span>

          </div>

        <div v-if="!collapsable || open" :class="{'overflow-auto': !hasStickyHeader && allowOverflow}">
          <table class="min-w-full divide-y divide-v3-gray-900 divide-opacity-10 dark:divide-white dark:divide-opacity-10 table-auto">
            <thead class="text-v3-gray-900 text-opacity-40 dark:text-v3-gray-400" :class="{'sticky top-0 z-10': hasStickyHeader, [tableHeaderBgClass]: tableHeaderBgClass}">
            <tr class="table-row">
              <slot name="headers"></slot>
            </tr>
            </thead>
            <tbody class="divide-y divide-v3-gray-900 dark:divide-white divide-opacity-10 dark:divide-opacity-10 text-v3-gray-800 dark:text-v3-gray-200" :class="bodyBgClass">
            <slot name="items" v-if="(entities && (entities.size >0 || (checkSize && hasEntities))) || !checkSize"></slot>
                <tr v-else>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-center" colspan="100">
                        <slot name="no-items">
                            No {{ title.toLowerCase() }} found.
                        </slot>
                    </td>
                </tr>
            </tbody>
          </table>
            <pagination-links :response="response" :detailsOpen="detailsOpen" :activeClass="activeClass"></pagination-links>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationLinks from "@/v3/components/pagination/PaginationLinks.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import OrderFilters from "@/v2/filters/OrderFilters.vue";
import OrderTabs from "@/v2/tabs/OrderTabs.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";

export default
{
    inject: ["$validator"],
  name: 'pagination',
    data(){
      return{
          filtersSelected: [],
          addFilter: false,
          clientId: null,
          projectId: null
      }
    },
  components:{
      ConfirmationAction,
      FormAction,
      OrderTabs,
      OrderFilters,
      PropertyOrField,
      PaginationLinks,
      CustomButton
  },
  props: {
      title: {
        type: String,
        default: null
      },
      collapsable: {
          type: Boolean,
          default: false
      },
      open:{
          type: Boolean,
          default: false
      },
      detailsOpen:{
          type: Boolean,
          default: false
      },
      response:{
          type: Object,
          default: null
      },
      entities:{
          type: Object|Array,
          default: null
      },
      filterAction:{
          type: Object,
          default: null
      },
      checkSize:{
          type: Boolean,
          default: true
      },
      headerColourClass:{
          type: String,
          default: 'bg-v3-gray-50 bg-opacity-10 dark:bg-white dark:bg-opacity-5'
      },
      withEditIcon: {
          type: Boolean,
          default: false
      },
      editing: {
          type: Boolean,
          default: false
      },
      withAltViewIcon: {
          type: Boolean,
          default: false
      },
      hasEntities:{
          type: Boolean,
          default: false
      },
      withDeleteIcon:{
          type: Boolean,
          default: false
      },
      hasStickyHeader:{
          type: Boolean,
          default: false
      },
      showTitleBar:{
          type: Boolean,
          default: true
      },
      bodyBgClass:{
          type: String,
          default: 'bg-transparent'
      },
      tableHeaderBgClass:{
          type: String,
          default: 'bg-v3-gray-50 bg-opacity-10 dark:bg-white dark:bg-opacity-5'
      },
      allowOverflow:{
          type: Boolean,
          default: true
      },
      activeClass:{
          type: String,
          default: null
      },
      hasLogoTitle:{
          type: Boolean,
          default: false
      },
      filtersActiveClass:{
          type: String,
          default: 'text-v3-green border-v3-green focus:text-v3-green focus:border-v3-green'
      }
  },
    mounted(){
      this.filterActionData;
    },
    computed:{
        filterActionData() {
            if (!this.filterAction) {
                return {};
            }
            var payload = {};
            let that = this;
            this.filterAction.fields.forEach(field => {
                if (field.value !== undefined) {
                    payload[field.name] = field.value;
                    if (field.value !== null) {
                        this.filtersSelected.push(field.name);
                    }
                    if((field.name === 'filter[client_id]' || field.name === 'organisation_id')){
                        this.clientId = field.value;
                    }
                    if(field.name === 'filter[project_id]'){
                        this.projectId = field.value;
                    }
                };
            });
            return payload;
        },
        saveFilterAction(){
            if(!this.response){
                return null;
            }
            return this.response.body.actions.filter(action => action.name === 'save-filter').first()
        }
    },
    methods:{
      toggleOpen(){
          if(this.collapsable){
              this.$emit('toggleOpen');
          }
      },
        onChange(event){
          if(event.target.value) {
              this.filtersSelected.push(event.target.value);
              this.addFilter = false;
          }
        },
        performAction(action) {
            if(this.filterAction.fields.filter(field => field.type !== 'hidden' && field.name === 'filter[client_id]').first()) {
                this.filterActionData['filter[client_id]'] = this.clientId;
            }
            if(this.filterAction.fields.filter(field => field.type !== 'hidden' && field.name === 'organisation_id').first()) {
                this.filterActionData['organisation_id'] = this.clientId;
            }
            if(this.filterAction.fields.filter(field => field.type !== 'hidden' && field.name === 'filter[project_id]').first()) {
                this.filterActionData['filter[project_id]'] = this.projectId;
            }
          let actionData = this.filterActionData;
            let parts = action.href.split('/api');
            console.log(this.clientId);

            this.$router.push({path: this.actionBaseUrl, query: actionData}).then(failure => {
                if(failure.fullPath !== window.location.href){
                    window.location.href = failure.fullPath;
                };
            }).catch();
        },
        getDependsOnValue(filterName){
            if((filterName === 'project_id' || filterName === 'user_id' || filterName === 'filter[user_id]' || filterName === 'filter[site_manager_ids]' || filterName === 'filter[project_id]') && this.clientId){
                return this.clientId
            }
            if((filterName === 'filter[site_id]' || filterName === 'filter[contract_id]') && this.projectId){
                return this.projectId
            }
            return null;
        },
        getDependsOn(filterName){
            if((filterName === 'project_id' || filterName === 'user_id' || filterName === 'filter[user_id]' || filterName === 'filter[site_manager_ids]' || filterName === 'filter[project_id]') && this.clientId){
                return 'client_id'
            }
            if((filterName === 'filter[site_id]' || filterName === 'filter[contract_id]') && this.projectId){
                return 'project_id'
            }
            return null;
        }
    }
}
</script>
