<template>
  <li class="py-1">
      <div class="relative show-link">
        <!-- Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50" -->
        <a @click="onClick"
           class=" group flex gap-x-3 rounded-full p-2 text-sm leading-6 font-semibold cursor-pointer" :class="{'bg-transparent text-v3-gray-600': !isActive, [activeClass]: isActive, [additionalClasses]: !isActive}">
            <slot name="icon"></slot>
        </a>
          <div class="hidden absolute bg-white dark:bg-v3-gray-900 rounded-xl h-full whitespace-nowrap z-10" style="

                top: 5px;
                left: 140%;
            ">
              <div class="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20 h-full whitespace-nowrap rounded-xl" :class="additionalClasses" style="padding:7px">
                {{ name }}
              </div>
          </div>
      </div>
  </li>
</template>
<script>
export default {
  name: 'navigation-link',
    props:{
      name:{
          type: String,
          default: null
      },
        isActive:{
            type: Boolean,
            default: false
        },
        open:{
            type: Boolean,
            default: false
        },
        href:{
            type: String,
            default: '#'
        },
        activeClass:{
            type: String,
            default:'text-v3-gray-100 dark:text-v3-gray-50'
        },
        additionalClasses:{
            type: String,
            default:null
        }
    },
    methods:{
      onClick(){
          if(this.href !== '#') {
              this.$router.push(this.href);
          }
          this.$emit('click');
      }
    }
}
</script>

<style scoped>
.show-link:hover div
{
    display:block
}
</style>
