<template>
  <div class="container mx-auto px-4 mb-4 block">
    <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4" >
        <stat v-for="item in items"
            :key="item.entity.properties.get('name')"
            :name="item.entity.properties.get('name')"
            :title="item.entity.properties.get('title')"
            :value="item.entity.properties.get('value')"
            :viewMoreLink="getLink(item)"
            :viewMoreLinkTitle="getLinkTitle(item)"
        ></stat>
    <dashboard-clocked-in></dashboard-clocked-in>

    <div class="col-span-2">
            <div data-v-5d648b30="" class="bg-v3-base-500 overflow-hidden shadow rounded-lg">
                <div data-v-5d648b30="" class="px-4 py-5 sm:p-6"><div data-v-5d648b30="" class="flex items-center">
                    <div data-v-5d648b30="" class="flex-shrink-0 bg-yellow-300 rounded-md p-3">
                        <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    </div>
                    <div data-v-5d648b30="" class="ml-5 w-0 flex-1">
                        <p data-v-5d648b30="" class="text-sm leading-5 font-medium text-gray-500 truncate">Project locations</p>
                        <GmapMap
                            :center="{lat:51.509865, lng:-0.118092}"
                            :zoom="10"
                            map-type-id="terrain"
                            class="h-64"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="false"
                                @click="toggleInfoWindow(m,index)"
                            />
                            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                                {{infoContent}}
                            </gmap-info-window>
                        </GmapMap>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Siren from "super-siren";
import { mapGetters } from "vuex";
import Stat from "./Stat";
import DashboardClockedIn from "./DashboardClockedIn";

export default {
    components: {
        Stat,
        DashboardClockedIn
    },
  data() {
    return {
      items: null,
        infoContent: '',
        infoWindowPos: null,
        infoWinOpen: false,
        ordersLink: null,
        infoOptions: {
            pixelOffset: {
                width: 0,
                height: -35
            }
        },
        markers: []
    };
  },
              methods: {
                  getLink(item) {
                      if (!item.entity.links.filter(link => link.rels.contains("view-more")).first()) {
              return null;
          }

          return item.entity.links.filter(link => link.rels.contains("view-more")).first().href;
      },
      getLinkTitle(item) {
          if (!item.entity.links.filter(link => link.rels.contains("view-more")).first()) {
              return null;
          }
          return item.entity.links.filter(link => link.rels.contains("view-more")).first().title;
      },
      toggleInfoWindow: function (marker, idx) {
          this.infoWindowPos = marker.position;
          this.infoContent = marker.infoText;

          //check if its the same marker that was selected if yes toggle
          if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
          }
          //if different marker set infowindow to open and reset current marker index
          else {
              this.infoWinOpen = true;
              this.currentMidx = idx;

          }
      }
  },
  created() {
    let follow = Siren.Helper.follow;

    Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

    Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
        Siren.get(process.env.VUE_APP_API_URL + "/api/")
        .then(
          follow("stats")
        )
        .then(res => {
          let siren = res.body;
          this.items = siren.entities;

        }, this).catch(error => {
            console.log('error fetching stats');
        });
        Siren.get(process.env.VUE_APP_API_URL + "/api/")
            .then(
                follow("projects")
            )
            .then(res => {
                let siren = res.body;
                let projects = siren.entities;
            projects.forEach(entity => {
                var projectName = entity.entity.properties.get('name');
                var lat = entity.entity.properties.get('latitude');
                var long = entity.entity.properties.get('longitude');
                if (lat && long) {
                    this.markers.push({
                        position: {
                            lat: lat,
                            lng: long
                        },
                        infoText: projectName
                    });
                }
            });
            }, this).catch(error => {
            console.log('error fetching projects');
        });
    })
  },
  computed: {
    ...mapGetters({
      accessToken: "getAccessToken"
    })
  }
};
</script>

<style lang="scss" scoped>
</style>
