<template>
  <div class="bg-v3-gray-50 bg-opacity-20 rounded-full h-fit dark:bg-v3-gray-50/20">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
          <div>
<!--              <navigation-link name="HQ Dash" v-if="hasLink('invoices') || hasLink('invoices-in') || hasLink('credit-notes') || hasLink('sages')" :is-active="isCurrentPage('/hq-dashboard')" :open="panelOpen" href="/hq-dashboard" activeClass="text-v3-pink opacity-50">-->
<!--                  <template v-slot:icon>-->
<!--                      <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"-->
<!--                           viewBox="0 0 24 24">-->
<!--                          <path fill="currentColor" d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3z"/>-->
<!--                      </svg>-->
<!--                  </template>-->
<!--              </navigation-link>-->
              <navigation-link name="Clients" v-if="hasLink('admin.clients')" :is-active="isCurrentPage('/clients')" :open="panelOpen" href="/clients"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" width="25" height="24" viewBox="0 0 25 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12.8742 7V3H2.91602V21H22.8323V7H12.8742ZM6.89928 19H4.90765V17H6.89928V19ZM6.89928 15H4.90765V13H6.89928V15ZM6.89928 11H4.90765V9H6.89928V11ZM6.89928 7H4.90765V5H6.89928V7ZM10.8825 19H8.89091V17H10.8825V19ZM10.8825 15H8.89091V13H10.8825V15ZM10.8825 11H8.89091V9H10.8825V11ZM10.8825 7H8.89091V5H10.8825V7ZM20.8407 19H12.8742V17H14.8658V15H12.8742V13H14.8658V11H12.8742V9H20.8407V19ZM18.8491 11H16.8574V13H18.8491V11ZM18.8491 15H16.8574V17H18.8491V15Z"
                              fill="currentColor"/>
                      </svg>
                  </template>
              </navigation-link>
              <navigation-link name="People" v-if="hasLink('admin.client.users')" :is-active="isCurrentPage('/hq-people')" :open="panelOpen" href="/hq-people"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                           viewBox="0 0 24 24">
                          <path fill="currentColor"
                                d="M16 17v2H2v-2s0-4 7-4s7 4 7 4m-3.5-9.5A3.5 3.5 0 1 0 9 11a3.5 3.5 0 0 0 3.5-3.5m3.44 5.5A5.32 5.32 0 0 1 18 17v2h4v-2s0-3.63-6.06-4M15 4a3.4 3.4 0 0 0-1.93.59a5 5 0 0 1 0 5.82A3.4 3.4 0 0 0 15 11a3.5 3.5 0 0 0 0-7"/>
                      </svg>
                  </template>
              </navigation-link>
              <navigation-link name="Projects" v-if="hasLink('admin.clients')" :is-active="isCurrentPage('/hq-projects')" :open="panelOpen" href="/hq-projects" activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg data-v-757e72ea="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-6 w-6 shrink-0 fill-current"><path data-v-757e72ea="" d="M336 304C336 295.2 343.2 288 352 288H384C392.8 288 400 295.2 400 304V336C400 344.8 392.8 352 384 352H352C343.2 352 336 344.8 336 336V304zM336 112C336 103.2 343.2 96 352 96H384C392.8 96 400 103.2 400 112V144C400 152.8 392.8 160 384 160H352C343.2 160 336 152.8 336 144V112zM352 256C343.2 256 336 248.8 336 240V208C336 199.2 343.2 192 352 192H384C392.8 192 400 199.2 400 208V240C400 248.8 392.8 256 384 256H352zM448 0C483.3 0 512 28.65 512 64V448C512 483.3 483.3 512 448 512H288C252.7 512 224 483.3 224 448V64C224 28.65 252.7 0 288 0H448zM448 48H288C279.2 48 272 55.16 272 64V448C272 456.8 279.2 464 288 464H448C456.8 464 464 456.8 464 448V64C464 55.16 456.8 48 448 48zM192 176H72C58.75 176 48 186.7 48 200V440C48 453.3 58.75 464 72 464H193.3C196.4 482.3 204.6 498.8 216.4 512H72C32.24 512 0 479.8 0 440V200C0 160.2 32.24 128 72 128H192V176zM144 320C152.8 320 160 327.2 160 336V368C160 376.8 152.8 384 144 384H112C103.2 384 96 376.8 96 368V336C96 327.2 103.2 320 112 320H144zM144 224C152.8 224 160 231.2 160 240V272C160 280.8 152.8 288 144 288H112C103.2 288 96 280.8 96 272V240C96 231.2 103.2 224 112 224H144z"></path></svg>
                  </template>
              </navigation-link>
            <navigation-link name="Invoices" v-if="hasLink('invoices')" :is-active="isCurrentPage('/invoices')" :open="panelOpen" href="/invoices" activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM96 128h80C184.8 128 192 120.8 192 112S184.8 96 176 96H96C87.16 96 80 103.2 80 112S87.16 128 96 128zM192 176C192 167.2 184.8 160 176 160H96C87.16 160 80 167.2 80 176S87.16 192 96 192h80C184.8 192 192 184.8 192 176zM197.5 308.8L191.1 307.2C168.3 300.2 168.8 296.1 169.6 292.5c1.375-7.812 16.59-9.719 30.27-7.625c5.594 .8438 11.73 2.812 17.59 4.844c10.39 3.594 21.83-1.938 25.45-12.34c3.625-10.44-1.891-21.84-12.33-25.47c-7.219-2.484-13.11-4.078-18.56-5.273V240c0-11.03-8.953-20-20-20s-20 8.969-20 20v5.992C149.6 250.8 133.8 264.8 130.2 285.7c-7.406 42.84 33.19 54.75 50.52 59.84l5.812 1.688c29.28 8.375 28.8 11.19 27.92 16.28c-1.375 7.812-16.59 9.75-30.31 7.625c-6.938-1.031-15.81-4.219-23.66-7.031l-4.469-1.625c-10.41-3.594-21.83 1.812-25.52 12.22c-3.672 10.41 1.781 21.84 12.2 25.53l4.266 1.5c7.758 2.789 16.38 5.59 25.06 7.512V416c0 11.03 8.953 20 20 20s20-8.969 20-20v-6.254c22.36-4.793 38.21-18.53 41.83-39.43C261.3 327 219.8 315.1 197.5 308.8z"/></svg>
              </template>
            </navigation-link>
              <navigation-link name="Purchase Invoices" v-if="hasLink('invoices-in')" :is-active="isCurrentPage('/invoices-in')" :open="panelOpen" href="/invoices-in"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M264 144C277.3 144 288 154.7 288 168C288 181.3 277.3 192 264 192H120C106.7 192 96 181.3 96 168C96 154.7 106.7 144 120 144H264zM264 320C277.3 320 288 330.7 288 344C288 357.3 277.3 368 264 368H120C106.7 368 96 357.3 96 344C96 330.7 106.7 320 120 320H264zM96 256C96 242.7 106.7 232 120 232H264C277.3 232 288 242.7 288 256C288 269.3 277.3 280 264 280H120C106.7 280 96 269.3 96 256zM80 40.39L120.4 5.778C129.4-1.926 142.6-1.926 151.6 5.778L192 40.39L232.4 5.778C241.4-1.926 254.6-1.926 263.6 5.778L304 40.39L344.4 5.778C351.5-.3214 361.5-1.72 370 2.196C378.5 6.113 384 14.63 384 24V488C384 497.4 378.5 505.9 370 509.8C361.5 513.7 351.5 512.3 344.4 506.2L304 471.6L263.6 506.2C254.6 513.9 241.4 513.9 232.4 506.2L192 471.6L151.6 506.2C142.6 513.9 129.4 513.9 120.4 506.2L80 471.6L39.62 506.2C32.5 512.3 22.49 513.7 13.97 509.8C5.456 505.9 0 497.4 0 488V24C0 14.63 5.456 6.112 13.97 2.196C22.49-1.72 32.5-.3214 39.62 5.778L80 40.39zM64.38 421.8C73.37 414.1 86.63 414.1 95.62 421.8L136 456.4L176.4 421.8C185.4 414.1 198.6 414.1 207.6 421.8L248 456.4L288.4 421.8C297.4 414.1 310.6 414.1 319.6 421.8L336 435.8V76.18L319.6 90.22C310.6 97.93 297.4 97.93 288.4 90.22L248 55.61L207.6 90.22C198.6 97.93 185.4 97.93 176.4 90.22L136 55.61L95.62 90.22C86.63 97.93 73.37 97.93 64.38 90.22L48 76.18V435.8L64.38 421.8z"/></svg>
                  </template>
              </navigation-link>

              <navigation-link name="Credit Notes" v-if="hasLink('credit-notes')" :is-active="isCurrentPage('/credit-notes')" :open="panelOpen" href="/credit-notes"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M80 256v64c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32V256c0-17.67-14.33-32-32-32h-160C94.33 224 80 238.3 80 256zM365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM96 128h80C184.8 128 192 120.8 192 112S184.8 96 176 96H96C87.16 96 80 103.2 80 112S87.16 128 96 128zM96 192h80C184.8 192 192 184.8 192 176S184.8 160 176 160H96C87.16 160 80 167.2 80 176S87.16 192 96 192zM288 384h-80c-8.844 0-16 7.156-16 16s7.156 16 16 16H288c8.844 0 16-7.156 16-16S296.8 384 288 384z"/></svg>
                  </template>
              </navigation-link>
              <navigation-link name="Manage Sage" v-if="hasLink('sages')" :is-active="isCurrentPage('/sages') || isCurrentPage('/sages_description') || isCurrentPage('/sages_nominal_code')" :open="panelOpen" href="/sages"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.61001 19.9272C5.39778 19.8408 3.57945 19.0738 2.41983 17.1661C1.49896 15.6517 1.43142 14.034 2.17039 12.4097C2.28742 12.1525 2.47133 11.9127 2.66794 11.7064C3.11935 11.2322 3.756 11.1766 4.27361 11.5309C4.71766 11.835 4.86746 12.4626 4.58859 13.0293C4.11913 13.9825 4.08569 14.9343 4.57254 15.8787C5.1236 16.9491 6.0545 17.4836 7.21879 17.6022C8.17109 17.6993 9.05451 17.4796 9.79014 16.8239C11.0688 15.6845 11.0822 13.3535 9.423 12.249C8.57101 11.6816 7.62205 11.3393 6.67175 10.9917C5.62583 10.6092 4.59996 10.1839 3.69113 9.52276C2.56027 8.70022 1.9069 7.60907 1.74306 6.20512C1.57654 4.78107 1.85207 3.4729 2.688 2.31277C3.64231 0.987852 4.95641 0.247696 6.57746 0.0534465C8.35299 -0.159558 9.95933 0.259083 11.3443 1.3998C12.3822 2.25449 12.9995 3.3664 13.1459 4.71476C13.1814 5.04163 13.2081 5.38659 13.1439 5.70409C13.0162 6.33708 12.4631 6.67333 11.7824 6.59764C11.1878 6.532 10.7344 6.06714 10.7224 5.45894C10.6682 2.70394 7.55718 1.55318 5.5857 2.76356C3.83959 3.83528 3.75199 6.35918 5.40781 7.56888C6.25378 8.18713 7.22749 8.53611 8.1945 8.89849C9.35144 9.33253 10.4997 9.784 11.4774 10.571C12.5828 11.4612 13.1386 12.6267 13.2162 14.0381C13.2924 15.4239 12.9787 16.6939 12.1127 17.7944C11.1885 18.9686 9.97271 19.6445 8.4941 19.8408C8.18648 19.8816 7.87617 19.9017 7.61135 19.9272H7.61001Z" fill="currentColor"/>
                      </svg>
                  </template>
              </navigation-link>
              <navigation-link name="Settings" :open="panelOpen" :isActive="isCurrentPage('/hq-settings')" href="/hq-settings"  activeClass="text-v3-white-100 bg-v3-gray-50 dark:text-v3-gray-900" additional-classes="text-v3-gray-50">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19.14 12.94c.04-.3.06-.61.06-.94c0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 0 0 .12-.61l-1.92-3.32a.488.488 0 0 0-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 0 0-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 0 0-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6s3.6 1.62 3.6 3.6s-1.62 3.6-3.6 3.6"/></svg>
                  </template>
              </navigation-link>
          </div>
          <div class="pt-12">
              <navigation-link name="My Dash" :open="panelOpen" href="/"  activeClass="text-v3-gray-50"  additional-classes="text-v3-gray-50 ring-2 ring-current">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                      </svg>
                  </template>
              </navigation-link>
          </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'HqNavigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: true
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path === url || this.$route.path.includes(url+'/')
      },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
    },

}
</script>
