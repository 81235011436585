<template>
    <div class="h-screen bg-v3-white-100 dark:bg-v3-gray-900 text-v3-base-800 dark:text-v3-base-300">
        <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script>
        <notification :message="message"></notification>
        <notification-without-timeout :message="messageWithoutTimeout"></notification-without-timeout>
        <div class="h-full">

            <div class="flex flex-col flex-1 mb-8 h-full" >
                <main class="flex-1 h-full max-h-screen overflow-hidden" tabindex="0" :class="{'bg-cover': backgroundImageUrl !== null}" :style="[backgroundImageUrl !== null ?{backgroundImage:`url(${backgroundImageUrl})`, backgroundPosition: 'top 80px left 0'}: null]">

                    <div class="flex flex-col h-full overflow-hidden zoom-container">
                        <div v-if="getPreviousUser()">
                            <div class="text-center py-2 dark:bg-black dark:text-gray-400">Signed in as {{user.name}} <a @click="signInPreviousUser" class="underline cursor-pointer">Sign Out</a></div>
                        </div>
                       <page-header :logo="logo" :mode="mode" :user="user" @signOut="logout" :links="links" :actions="actions" @toggleMode="toggleMode">
                           <template v-if="$slots.headerLottieLight && (mode === 'light' || mode === null)" v-slot:lottieHeaderLight>
                               <slot name="headerLottieLight"></slot>
                           </template>
                           <template v-if="$slots.headerLottieDark && mode === 'dark'" v-slot:lottieHeaderDark>
                               <slot name="headerLottieDark"></slot>
                           </template>
                       </page-header>
                        <slot v-bind:links="links" v-bind:actions="actions" v-bind:logo="logo"></slot>
                   </div>
                </main>
                <div class="text-xs flex justify-end mr-4 items-center text-v3-gray-800 dark:text-v3-gray-200">Powered By
                    <img v-if="true && mode === 'dark'" src="../../../images/logo-header-dark.png" class="ml-1 opacity-80 h-3">
                    <img v-else-if="true && (mode === 'light' || mode === null)" src="../../../images/logo-header-light.png" class="ml-1 opacity-80 h-3">
                    <span v-else-if="false" class="text-red-500 text-sm bold ml-1 ">TEST</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Siren from "super-siren";
import {mapGetters} from "vuex";
import Notification from "@/components/Notification";
import NotificationWithoutTimeout from "@/components/NotificationWithoutTimeout";
import vClickOutside from 'v-click-outside';
import formatting from "@/mixins/formatting";
import axios from "axios";
import PageHeader from "@/v3/components/headers/PageHeader.vue";

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    inject: ["$validator"],
    mixins: [formatting],
    name: "mainTemplate",
    data(){
        return{
            links: null,
            actions:null,
            profileMenuIsOpen:false,
            displaySettings:false,
            userHasNotifications:false,
            userEditAction:null,
            userResetPasswordAction:null,
            showPasswordField: false,
            logo: null,
            submittingModal: false,
            mode: null,
            playInterval: null
        }
    },
    components:{
        PageHeader,
      NotificationWithoutTimeout,
      Notification
    },
    props:{
        backgroundImageUrl: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
            message: "getMessage",
            messageWithoutTimeout: "getMessageWithoutTimeout"
        }),
        formEditData() {
            if (!this.userEditAction.fields) {
                return {};
            }
            var payload = {};
            this.userEditAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        formPasswordData() {
            if (!this.userResetPasswordAction.fields) {
                return {};
            }
            var payload = {};
            this.userResetPasswordAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        initials() {
            if (this.user && this.user.name) {
                return this.user.name
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
    },
    mounted() {
        if(localStorage.getItem('mode')) {
            this.mode = localStorage.getItem('mode');
            this.$emit('setMode', this.mode);
            if (this.mode === 'dark') {
                document.getElementsByTagName('html')[0].classList.add('dark');
            } else {
                document.getElementsByTagName('html')[0].classList.remove('dark');
            }
        }
        let follow = Siren.Helper.follow;
        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
            let siren = res.body;
            this.links = siren.links;
            this.actions = siren.actions;
            return res;
        }, this).then(
            follow("current-user")
        )
            .then(res => {
                let siren = res.body;
                this.$store.dispatch(
                    "setUserEntity",
                    {'userEntity': siren}
                );
                this.user.name = siren.properties.get('name');
                this.user.has_notifications = siren.properties.get('has_notifications');
                this.userHasNotifications = this.user.has_notifications;
                this.user.profile_image = siren.properties.get('profile_image');
                this.user.oss_profile_image = siren.properties.get('oss_profile_image');
                this.userEditAction = siren.findActionByName('update-user');
                this.userResetPasswordAction= siren.findActionByName('reset-user-password');
                this.$emit('setClientId', siren.properties.get('client_id'));
                let client = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('client')).first();
                if (client !== undefined){
                    let logo = client.entity.properties.get('logo');
                    this.logo = logo;
                    if (this.logo !== null) {
                        if (logo && logo.startsWith('public/')) {
                            this.logo = this.logo.replace('public/', '');
                        }
                        this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo
                    }
                }

                let directSupplier = siren.embeddedEntities.filter(entity => entity.entity.classes.contains('direct-supplier')).first();
                if(directSupplier !== undefined){
                    let logo = directSupplier.entity.properties.get('logo');
                    this.logo = logo;
                    if (logo.startsWith('public/')){
                        this.logo = this.logo.replace('public/', '');
                    }
                    this.logo = process.env.VUE_APP_API_URL + '/storage/logo/' + this.logo;
                }
                this.$emit('slotLoaded', {links: this.links, actions: this.actions, logo: this.logo});

                if(siren.properties.get('is_restricted')){
                    this.$store.dispatch("logout").then(() => this.$router.push("/login"));
                }
            }, this).catch(error => {
                console.log(error);
            console.log('error fetching user data');
        })

        const player = document.querySelector("dotlottie-player");

        const playInterval = setInterval(function(){

            try {
                player.play();
                setTimeout(function () {
                    clearInterval(playInterval)
                }, 500);
            }
            catch(err) {
                console.log(err);
            }
        },100);



    },
    methods: {
        isActive(name){
            if(name === 'orders'){
                return this.$route.path.includes('/orders') || this.$route.path.includes('/tender-order') || this.$route.path.includes('/return-requests') || this.$route.path.includes('/rental-items') ||
                    this.$route.path.includes('take-off-orders') || this.$route.path.includes('/schedule-manager');
            }
            if(name === 'office'){
                return (this.$route.path.includes('/office') || this.$route.path.includes('/invoices') || this.$route.path.includes('/invoices-in') || this.$route.path.includes('/credit-notes') ||
                    this.$route.path.includes('/sages')  || this.$route.path.includes('/reports') ||
                    this.$route.path.includes('/invoice-reports')) && !this.$route.path.includes('/office-items');
            }
            if(name === 'manage'){
                return this.$route.path.includes('/manage') || this.$route.path.includes('/rfis') || this.$route.path.includes('/suppliers') || this.$route.path.includes('/holiday-requests') ||
                    this.$route.path.includes('/qa-tools') || this.$route.path.includes('/quality-assessments') || this.$route.path.includes('/qa-trackers') || this.$route.path.includes('/qa-categories') ||
                    this.$route.path.includes('/payment-vouchers')|| this.$route.path.includes('/site-vehicle-entry-logs')|| this.$route.path.includes('/evac') ||
                    this.$route.path.includes('/materials')|| this.$route.path.includes('/office-items')|| this.$route.path.includes('/vehicles') || this.$route.path.includes('/inventory') ||
                    this.$route.path.includes('/costing') || this.$route.path.includes('/quotes-mismatch') || this.$route.path.includes('/client-address-book') || this.$route.path.includes('/pre-checks')||
                    this.$route.path.includes('/subscriptions')|| this.$route.path.includes('/product-library')|| this.$route.path.includes('/product-categories');
            }
            if(name === 'oss'){
                return this.$route.path.includes('/onsiteselfie/daily-reports') || this.$route.path.includes('/on-site-selfie/weekly-reports') || this.$route.path.includes('/on-site-selfie/timesheet-builder');
            }
            if(name === 'hub'){
                return this.$route.path.includes('/help-hub/cms');
            }
            if(name === 'admin'){
                return this.$route.path.includes('/admin') || this.$route.path.includes('/clients') || this.$route.path.includes('/users') || this.$route.path.includes('/default-client-users') ||
                    this.$route.path.includes('/companies') || this.$route.path.includes('/direct-suppliers') || this.$route.path.includes('/manufacturers') || this.$route.path.includes('/products') ||
                    this.$route.path.includes('/product-types') || this.$route.path.includes('/dashboards') || this.$route.path.includes('/dashboard-widgets') || this.$route.path.includes('/invite-client') ||
                    this.$route.path.includes('/invite-user');
            }
            if(name === 'cms-news'){
                return this.$route.path.includes('/cms-news') && !this.$route.path.includes('/admin');
            }
            if(this.$route.path.includes('/' + name)){
                return true;
            };
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        logout() {
            window.localStorage.removeItem('hasShownWelcomeVideo');
            this.$store.dispatch("logout").then(() => this.$router.push("/login"));
        },
        canAccessHelpMenu(){
            return this.hasLink('cms-help-hub')
        },
        hasOfficeLink(){
            return this.hasSagesLink() || this.hasLink('invoices') || this.hasLink('invoices-in') || this.hasLink('unapproved-invoices-in') || this.hasLink('credit-notes') || this.hasLink('reports');
        },
        hasSagesLink() {
            return this.hasLink('sages') || this.hasLink('sage-descriptions') || this.hasLink('sage-nominal-codes');
        },
        hasAnInventoryLink(){
            return this.hasLink('materials') || this.hasLink('office-items') || this.hasLink('vehicles')
        },
        hasAnAdminLink(){
            return this.hasLink('admin.clients') || this.hasLink('admin.client') || this.hasLink('admin.projects') || this.hasLink('admin.contracts') || this.hasLink('admin.users') || this.hasLink('admin.suppliers') || this.hasLink('admin.companies') || this.hasLink('admin.products')|| this.hasLink('admin.product-types') || this.hasLink('admin.direct-suppliers') || this.hasLink('admin.direct-supplier') || this.hasAction('invite-user') || this.hasAction('invite-client');
        },
        hasAction(key){
            if(this.actions === null){
                return 0;
            }
            return this.actions.filter(action => action.name === key).size;
        },
        resetSettings(){
            this.showPasswordField = false;
            this.displaySettings = false;
            this.formEditData.name = this.userEditAction.fields.get('name').value;
            this.formEditData.email = this.userEditAction.fields.get('email').value;
            this.formEditData.contact_number = this.userEditAction.fields.get('contact_number').value;
            this.formPasswordData.password = '';
            this.formEditData.profile_image = '';
            this.errors.clear("user-details");
            this.errors.clear("password");
        },
        perform() {
            this.displaySettings = true;
            this.submittingModal = true;
            this.errors.clear("user-details");
            this.errors.clear("password");
            this.userEditAction.perform(this.formEditData).then(res => {
                if (this.showPasswordField) {
                    this.userResetPasswordAction.perform(this.formPasswordData).then(res => {
                        this.successfullySubmitted(res);
                    }).catch(error => {
                        this.submittingModal = false;
                        if(error.status !== 422) {
                            alert('There was an error processing your request.' + error);
                        }
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text),
                            'password'
                        );
                        this.$emit('failure');
                    })
                }else{
                    this.successfullySubmitted(res);
                }
            }).catch(error => {
                this.submittingModal = false;
                if(error.status !== 422) {
                    alert('There was an error processing your request.' + error);
                }
                this.$setLaravelValidationErrorsFromResponse(
                    JSON.parse(error.response.text),
                    "user-details"
                );
                this.$emit('failure');
            });
        },
        successfullySubmitted(res){
            this.displaySettings = false;
            this.submittingModal = false;
            this.$router.go(0);
            this.$emit('success', res);
        },
        closeDisplaySettigns()
        {
            this.displaySettings = false;
        },
        closeProfileMenu()
        {
            this.profileMenuIsOpen = false
        },
        getAdminLink()
        {
            if(!this.hasLink('admin.clients') && this.hasLink('admin.client')){
                return this.getLinkHref('admin.client');
            }else{
                return process.env.VUE_APP_BASE_URL + '/admin';
            }
        },
        isUrlStaging(){
            return process.env.VUE_APP_BASE_URL === 'https://test.companiesms.co.uk'
        },
        getPreviousUser(){
            return window.localStorage.getItem('previous_user')
        },
        signInPreviousUser(){
            let previousUser = this.getPreviousUser();
            axios.post(process.env.VUE_APP_API_URL + "/api/users/" + previousUser + "/login-original-user", {}, {
                headers: { Authorization: `Bearer ${this.accessToken}` }
            }).then(res => {
                this.$store
                    .dispatch("loginAsDifferentUser", {
                        user: res.data.properties["user"],
                        accessToken: res.data.properties["access_token"],
                        previousUser: res.data.properties['previous_user']
                    }).then(( response ) =>  {
                        this.$router.go(0);
                    })
                }).catch(({ response }) => {alert(response.data.message)});
        },
        toggleMode(){
            if(this.mode === 'dark'){
                localStorage.setItem('mode', 'light')
                this.mode = 'light';
                this.$emit('toggleMode', this.mode);
                document.getElementsByTagName('html')[0].classList.remove('dark');
                setTimeout(function() {
                    const player = document.querySelector("dotlottie-player");
                    player.load(player.src);
                    var endOfLottie = setInterval(function(){
                        try{
                            player.seek(60);
                        }catch(err){
                            console.log(err);
                        }
                    }, 1);
                }, 50);
                return;
            }
            localStorage.setItem('mode', 'dark')
            this.mode = 'dark';
            this.$emit('toggleMode', this.mode);
            document.getElementsByTagName('html')[0].classList.add('dark');
            setTimeout(function() {
                const player = document.querySelector("dotlottie-player");
                player.load(player.src);

                var endOfLottie = setInterval(function(){
                        player.seek(60);
                }, 1);
            }, 50);
        }
    }
}
</script>
<style scoped>
@media (max-height: 1000px){
    .zoom-container{
        //zoom:0.75;
    }
}
</style>
