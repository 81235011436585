<template>
    <dashboard-widget-template title="Leave Requests">
        <template v-slot:body>
            <pagination class="mt-2" title="Leave Requests" :entities="leaveRequests" :showTitleBar="false" bodyBgClass="bg-transparent" tableHeaderBgClass="bg-transparent" :allowOverflow="false">
                <template v-slot:headers>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Date To</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Date From</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Duration</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Status</th>
                </template>
                <template v-slot:items v-if="leaveRequests">
                    <tr v-for="entity in leaveRequests" class="cursor-pointer" @click="goToUsersRequests(entity.entity.properties.get('user_id'))">
                        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                            <div>
                                {{ entity.entity.properties.get('user') }}
                            </div>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                            {{ entity.entity.properties.get('start_date') }}
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                            {{ entity.entity.properties.get('end_date') }}
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                            {{ entity.entity.properties.get('days_requested') }}
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm">
                            <div class="flex items-center">
                                <status-badge :status="entity.entity.properties.get('status')" :name="entity.entity.properties.get('status').toLowerCase()"></status-badge>
                            </div>
                        </td>
                    </tr>
                </template>
            </pagination>
        </template>
    </dashboard-widget-template>
</template>

<script>
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import Pagination from "@/v3/components/pagination/Pagination.vue";
import StatusBadge from "@/v3/components/StatusBadge.vue";

export default {
    components: {StatusBadge, Pagination, DashboardWidgetTemplate},
    props:{
        size:{
            type: String,
            default: null
        },
        leaveRequests:{
            type: Object|Array,
            default: null
        }
    },
    methods:{
        goToUsersRequests(userId){
            this.$router.push('/holiday-requests?filter[user_id]=' + userId);
        }
    }

}
</script>
