<template>
    <div
        @keydown.esc="toggleLocation">
        <template v-if="showLocation">
            <portal v-if="usePortal" to="destination">
            <photo-viewer :fileName="fileName" @removeFromDisplay="showLocation = false"></photo-viewer>
            </portal>
            <photo-viewer v-else :fileName="fileName" @removeFromDisplay="showLocation = false"></photo-viewer>
        </template>
        <a href="#" @click.prevent="toggleLocation" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
        </a>
    </div>
</template>

<script>
    import PhotoViewer from "@/pages/PhotoViewer";

    export default {
        components: {
            PhotoViewer,
        },
        props: {
            fileName: {
                type: String
            },
            usePortal:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showLocation: false
            }
        },
        methods: {
            toggleLocation()
            {
                this.showLocation = !this.showLocation
            }
        },
    }
</script>

<style scoped>

</style>
