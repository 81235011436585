<template>
    <div class="h-full">
    <stat v-if="type!=='all' && item"
          :key="item.entity.properties.get('name')"
          :name="item.entity.properties.get('name')"
          :title="item.entity.properties.get('title')"
          :value="item.entity.properties.get('value')"
          :viewMoreLink="getLink(item)"
          :viewMoreLinkTitle="getLinkTitle(item)"
          :mode="mode"
    >
        </stat>
        <order-stats v-else-if="type === 'all'" :items="allItems">
        </order-stats>
    </div>
</template>

<script>
    import Siren from "super-siren";
    import { mapGetters } from "vuex";
    import Stat from "../Dashboard/Stat";
    import OrderStats from "@/components/Dashboard/OrderStats";

    export default {
        components: {
            Stat,
            OrderStats
        },
        data() {
            return {
                item: null,
                allItems: null
            };
        },

        props: {
            type: {
                type: String,
            },
            mode:{
                type: String,
                default: null
            }
        },
        methods: {
            getLink(item) {
                if (!item.entity.links.filter(link => link.rels.contains("view-more")).first()) {
                    return null;
                }

                return item.entity.links.filter(link => link.rels.contains("view-more")).first().href;
            },
            getLinkTitle(item) {
                if (!item.entity.links.filter(link => link.rels.contains("view-more")).first()) {
                    return null;
                }
                return item.entity.links.filter(link => link.rels.contains("view-more")).first().title;
            }
        },
        created() {
            let follow = Siren.Helper.follow;

            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.Client.removeHeader('Cache-Control');
            Siren.Client.addHeader('Cache-Control', 'max-age=7200');

            Siren.get(process.env.VUE_APP_API_URL + "/api/").then(response => {
                Siren.get(process.env.VUE_APP_API_URL + "/api/")
                    .then(
                        follow("stats")
                    )
                    .then(res => {
                        let siren = res.body;
                        this.allItems = siren.entities
                            .filter(entity => entity.rels.contains('stat'));
                        this.allItems
                            .forEach(entity => {
                                if (entity.entity.properties.get('name') === this.type) {
                                    this.item = entity
                                }
                            });
                    }, this);
            })
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    };
</script>
