<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10">
            <label v-if="showLabel" for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3">{{field.title}}<span class="text-v3-red ml-1" v-if="required">*</span></label>
            <textarea
                :name="field.name"
                class="p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 form-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-0 rounded-md"
                :placeholder="field.title"
                v-model="propsVal"
                @change="change"
                @blur="blur"
                ref="input"
            ></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String
            },
            field: {
                type: Object
            },
            showLabel:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                propsVal: this.value
            }
        },
        methods: {
            change() {
                this.$emit("change");
            },
            blur() {
                this.$emit("blur");
            },
            updateVal(val) {
                this.propsVal = val;
            },
        },
        computed:{
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required')
                }
                return false;
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
