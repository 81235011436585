<template>
    <div class="overflow-hidden shadow rounded-lg h-full bg-v3-base-500 text-v3-gray-400 dark:bg-v3-gray-900 dark:text-v3-gray-500">
        <div class="h-full flex flex-col justify-center items-center ">
            <div class="">

<!--                <div class="current-date text-gray-400 text-sm">-->
<!--                    {{currentDate.format('ddd D MMM YYYY')}}-->
<!--                </div>-->
<!--                <div class="current-time text-2xl text-purple-800 font-bold">-->
<!--                    {{currentDate.format('hh:mm:ss')}} <span class="text-gray-400 text-sm font-normal"> {{currentDate.format('a')}}</span>-->
<!--                </div>-->
                <dt class="mt-4">
                    <div class="absolute rounded-md p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <div class="ml-16 current-date text-gray-400 text-sm">
                        {{currentDate.format('ddd D MMM YYYY')}}
                    </div>
                </dt>
                <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
                    <div class="current-time text-2xl text-purple-800 font-bold">
                        {{currentDate.format('hh:mm:ss')}} <span class="text-gray-400 text-sm font-normal"> {{currentDate.format('a')}}</span>
                    </div>
                </dd>
            </div>
        </div>





    </div>
</template>

<script>


    import Button from "../Button";
    import moment from "moment";

    export default {
        data() {
            return {
                currentDate: moment(new Date)
            }
        },
        props:{
            mode:{
                type: String,
                default: null
            }
        },
        mounted: function () {
            setInterval(function () {
                this.currentDate = moment(new Date)
            }.bind(this), 1000)
        }
    };
</script>

