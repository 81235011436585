<template>
    <div class="grid grid-cols-7 grid-rows-3 gap-2 h-full w-full rounded-xl zoom-container">
        <div class="bg-v3-gray-50 bg-opacity-10 w-full rounded-xl overflow-auto">
            <date-picker v-model="date" :highlighted="highlighted" :inline="true" wrapper-class="calendar-wrapper" calendar-class="calendar-main" :bootstrap-styling="true"></date-picker>
        </div>
        <div class="col-span-3 row-span-2 bg-v3-gray-50 bg-opacity-10 w-full rounded-xl overflow-auto">
            <leave-request-widget size="large" :leaveRequests="leaveRequests"></leave-request-widget>
        </div>

        <div class="col-start-5 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
<!--            <calculator></calculator>-->
            <procurement-stats size="small" :stats="stats"></procurement-stats>


        </div>
        <div class="col-span-2 row-span-2 col-start-6 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
            <my-orders-widget size="large" :orders="orders"></my-orders-widget>


        </div>
        <div class="row-span-2 row-start-2 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
            <notifications></notifications>
        </div>
        <div class="col-start-5 row-start-2 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
            <procurement-stats size="small" :stats="stats" :startFrom="1"></procurement-stats>


        </div>
        <div class="col-span-3 col-start-2 row-start-3 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
            <procurement-stats size="long" :stats="stats"></procurement-stats>


        </div>
        <div class="col-start-5 row-start-3 col-span-2 bg-v3-gray-50 bg-opacity-10 rounded-xl overflow-auto">
            <on-site-selfie-clock-ins-widget size="small" :checkins="userCheckins"></on-site-selfie-clock-ins-widget>

        </div>
        <div class="col-start-7 row-start-3 bg-v3-gray-50 bg-opacity-10 rounded-xl auto">
            <advert-viewer size="small" :file="dashboardAddFile" :link="dashboardAddLink"></advert-viewer>
        </div>
    </div>
</template>
<script>
import Notifications from "@/v3/components/dashboardWigets/Notifications.vue";
import DatePicker from "vuejs-datepicker";
import Calculator from "@/v3/components/dashboardWigets/Calculator.vue";
import AllStats from "@/components/Widgets/AllStats.vue";
import DashboardStats from "@/components/Dashboard/DashboardStats.vue";
import OrderStats from "@/components/Dashboard/OrderStats.vue";
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";
import ProcurementStats from "@/v3/components/dashboardWigets/ProcurementStats.vue";
import LeaveRequestWidget from "@/v3/components/dashboardWigets/LeaveRequestWidget.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import MyOrdersWidget from "@/v3/components/dashboardWigets/MyOrdersWidget.vue";
import dayjs from "dayjs";
import OnSiteSelfieClockInsWidget from "@/v3/components/dashboardWigets/OnSiteSelfieClockInsWidget.vue";
import AdvertViewer from "@/v3/components/dashboardWigets/AdvertViewer.vue";

export default {
    data(){
        return {
            'date': new Date(),
            stats: null,
            orders: null,
            userCheckins: null,
            dashboardAddFile: null,
            dashboardAddLink: null,
            leaveRequests: null
        }
    },
    components: {
        AdvertViewer,
        OnSiteSelfieClockInsWidget,
        MyOrdersWidget,
        LeaveRequestWidget,
        ProcurementStats,
        DashboardWidgetTemplate,
        OrderStats,
        DashboardStats,
        AllStats,
        Notifications,
        DatePicker,
        Calculator
    },

    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            userEntity: "getUserEntity"
        }),
        ossDetails(){
            // this.ossDefaultAction = res.body.actions.filter(action => action.name === 'save-as-default-project-oss-filter').first();
            // this.projects = res.body.actions.filter(action => action.name === 'save-as-default-project-oss-filter').first().fields.first(field => field.name === 'identifier').options
            // if (res.body.properties.get('identifier') != null) {
            //     this.defaultFilter = res.body.properties.get('identifier');
            //     this.key = this.selectedProject = res.body.properties.get('identifier');
            //     this.filterClockedInUsersByProject(this.selectedProject)
            // }
        },
        highlighted:function() {
            return {
                dates: [ // Highlight an array of dates
                    new Date(),
                ]
            }
        }
    },
    created() {
        let follow = Siren.Helper.follow;

        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/")
            .then(
                follow("stats")
            )
            .then(res => {
                let siren = res.body;
                this.stats = siren.entities
                    .filter(entity => entity.rels.contains('stat'));

            }, this);
        Siren.get(process.env.VUE_APP_API_URL + "/api/orders?without_actions=1")
            .then(res => {
                let siren = res.body;
                this.orders = siren.entities
                    .filter(entity => entity.rels.contains('order'));

            }, this);

        Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);

        Siren.get(process.env.VUE_APP_API_URL + "/api/")
            .then(
                follow("dashboards")
            )
            .then(res => {
                let siren = res.body;
                this.dashboardAddFile = siren.properties.get('advert_file4');
                this.dashboardAddLink = siren.properties.get('advert_link4');
            }, this);

        Siren.get(process.env.VUE_APP_API_URL + "/api/holiday-requests?without_actions=1")
            .then(res => {
                this.leaveRequests = res.body.entities;
                console.log(res);
            }, this);
    },
    mounted(){
        if(this.userEntity) {
            var client = this.userEntity.entities.filter(entity => {
                return entity.entity.classes.includes('client')
            })[0] ?? null;

            if (client !== undefined) {
                let clientId = client.entity.properties['id'];
                if (clientId) {
                    this.loadOssDetails(clientId);
                }
            }
        }
    },
    methods: {
        loadOssDetails(clientId) {
            if (!this.userCheckins) {
                if (clientId) {
                    let currentDate = dayjs(new Date()).format('YYYY-MM-DD');
                    Siren.get(process.env.VUE_APP_API_URL + "/api/on-site-selfie/daily-reports/" + clientId + '?date=' + currentDate).then(response => {
                        this.userCheckins = response.body

                    }, this);
                }
            }
        }
    }
}
</script>

<style>
.calendar-wrapper{
    width: 100% !important;
    height: 100% !important;
}
.calendar-main{
    width: 100% !important;
    height: 100% !important;
    background-color: inherit !important;
    border: none !important;
    color: #3A5164 !important;
}
.calendar-main header .next:after{
    border-left: 10px solid #3A5164 !important;
}
.calendar-main header .prev:after{
    border-right: 10px solid #3A5164 !important;
}
.dark .calendar-main{
    color: #BAC0C4 !important;
}
.dark .calendar-main header .next:after{
    border-left: 10px solid #BAC0C4 !important;
}
.dark .calendar-main header .prev:after{
    border-right: 10px solid #BAC0C4 !important;
}
.calendar-main header span:hover{
    background-color: #5E6E77 !important;
}
.calendar-main .cell.selected{
    background-color: #5E6E77 !important;
    color: white !important;
}
.calendar-main .cell.day:hover, .calendar-main .cell.month:hover, .calendar-main .cell.year:hover{
    border: 1px solid #5E6E77 !important;
}
.calendar-main .cell{
    border-radius: 0.5rem
}
</style>

<style scoped>
@media (max-height: 1000px){
    .zoom-container{
        zoom:0.75;
    }
}
</style>
