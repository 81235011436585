<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="px-4 py-3 flex items-center justify-between border-t border-v3-gray-900 dark:border-white border-opacity-10 dark:border-opacity-10 sm:px-6 text-v3-gray-800 bg-v3-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 dark:text-v3-gray-200" v-if="paginationProperties && paginationLinks && paginationProperties.lastPage !== 1 && paginationProperties.links">
        <div  class="flex items-center text-v3-gray-600 dark:text-v3-gray-400">
            <a v-if="paginationLinks.prev" @click="navigate(paginationLinks.prev.href)" class="flex items-center cursor-pointer">
            <span class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400 inline-flex">
                <svg class="w-6 h-6"  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/></svg>
            </span>
            </a>
        </div>
        <div class="flex-1 flex justify-between sm:hidden">
            <a v-if="paginationLinks.prev" @click="navigate(paginationLinks.prev.href)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Previous
            </a>
            <a v-if="paginationLinks.next" @click="navigate(paginationLinks.next.href)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                Next
            </a>
        </div>
        <div class="hidden sm:flex sm:flex-row-reverse sm:items-center sm:justify-between">
            <div>
                <nav class="relative z-0 inline-flex -space-x-px cursor-pointer" aria-label="Pagination">
                    <template v-for="(link, index) in paginationProperties.links">
                        <a v-if="index !== 0 && index !== paginationProperties.links.length - 1 &&((detailsOpen && parseInt(link.label) >= parseInt(paginationProperties.currentPage) - 2 && parseInt(link.label) <= parseInt(paginationProperties.currentPage) + 2) || !detailsOpen)"
                            class="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-v3-gray-600 hover:bg-v3-gray-100 dark:text-v3-gray-400 dark:hover:text-v3-gray-200 dark:hover:bg-v3-base-800"
                           tabindex="0"
                           :class="{[activeClass]: parseInt(link.label) === parseInt(paginationProperties.currentPage)}"
                            @click="navigate(link.url)"
                        >{{link.label}}</a>
                    </template>
                </nav>
            </div>
        </div>
        <div class="flex items-center text-v3-gray-600 dark:text-v3-gray-400" >
            <a @click="navigate(paginationLinks.next.href)" class="cursor-pointer flex items-center" v-if="paginationLinks.next">
                <span class="bg-v3-gray-50 bg-opacity-10 rounded-md mr-2 p-1 text-v3-gray-600 dark:text-v3-gray-400 inline-flex ml-2">
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/></svg>
                </span>
            </a>
        </div>



    </div>
</template>

<script>
import Siren from "super-siren";
import { mapActions, mapGetters } from "vuex";
let follow = Siren.Helper.follow;
export default {
  name: "PaginationLinks",
  props: {
      response: {
          type: Object
      },
      detailsOpen: {
          type: Boolean
      },
      activeClass:{
          type: String,
          default: 'bg-v3-gray-100 dark:bg-v3-base-800 dark:text-v3-gray-200'
      }
  },
  computed: {
    paginationProperties() {
      if(!this.response){
        return null
      }

      this.from = this.response.body.properties.get("from");
      this.to = this.response.body.properties.get("to");
      this.total = this.response.body.properties.get("total");
      this.currentPage = this.response.body.properties.get("current_page");
      this.lastPage = this.response.body.properties.get("last_page");
      this.links = this.response.body.properties.get("links");

      return {
        from: this.from,
        to: this.to,
        total: this.total,
        currentPage: this.currentPage,
        lastPage: this.lastPage,
        links: this.links
      }
    },
    paginationLinks(){
      if(!this.response){
        return null
      }

      return {
        next: this.response.body.links.filter(link => link.rels.contains("next_page")).first(),
        prev: this.response.body.links.filter(link => link.rels.contains("prev_page")).first()};
    },
    ...mapGetters({
        accessToken: "getAccessToken"
    })
  },
  methods: {
    navigate(link) {
      let parts = link.split("/api");
      this.$router.push(parts[1]);
      window.location.href = parts[1];
    },
    navigateAdditionalNext(link) {
      Siren.Client.addHeader(
          "Authorization",
          `Bearer ${this.accessToken}`
      );


      Siren.get(link.href).then(res => {
        let siren = res.body;
        let next = siren.links
          .filter(link => link.rels.contains("next_page"))
          .first();
        let parts = next.href.split("/api");
        this.$router.push(parts[1]);
      }, this);
    },
    navigateAdditionalPrev(link) {
      Siren.Client.addHeader(
          "Authorization",
          `Bearer ${this.accessToken}`
      );

      Siren.get(link.href).then(res => {
        let siren = res.body;
        let next = siren.links
          .filter(link => link.rels.contains("prev_page"))
          .first();
        let parts = next.href.split("/api");
        this.$router.push(parts[1]);
      }, this);
    },
  }
};
</script>
