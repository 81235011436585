<template>
    <div class="rounded-md flex-grow">
        <div class="focus-within:z-10 ">
            <label v-if="showLabel" for="email" class="block text-sm font-medium leading-5 text-v3-gray-900 dark:text-white mt-3">{{field.title}}<span class="text-v3-red ml-1" v-if="required">*</span></label>
            <span v-if="getFieldOptions && !isBeingChanged">
                <template v-if="propsVal === 'remove'">
                    <div>Being Removed</div>
                </template>
                <template v-else>
                    <a class="text-sm text-v3-base-700 dark:text-v3-gray-300 underline" v-if="getFieldOptions && !isBeingChanged" href="#" @click.prevent="togglePDF">Download <template v-if="showAttachmentUrl">({{getFieldOptions}})</template><template v-else>Image</template></a><br>
                    <template v-if="showPDF">
                        <download-pdf :url="attachmentUrl" @removeFromDisplay="showPDF = false"></download-pdf>
                    </template>
                </template>
                <a class="underline text-v3-base-800 dark:text-gray-200"  v-if="getFieldOptions && !isBeingChanged" @click.prevent="isBeingChanged = true" :name="field.name" href="#">Click here to change</a>
            </span>
            <input v-else
            :name="field.name"
            class="form-input p-1 bg-v3-gray-900 bg-opacity-5 text-v3-gray-900 text-opacity-70 placeholder-v3-gray-900 placeholder-opacity-70 dark:bg-white dark:bg-opacity-5 dark:text-white dark:text-opacity-70 dark:placeholder-white dark:placeholder-opacity-70 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-0 rounded-md"
            :placeholder="field.title"
            :accept="acceptRule"
            data-cy="file-upload"
            type="file"
            @change="onFileChange"
            ref="input"
            :capture="useCamera ? '': null"
            />
        </div>

    </div>
</template>

<script>
    import DownloadPdf from "../../../pages/DownloadPdf";

    export default {
        components: {DownloadPdf},
        props: {
            value:{
                type:String
            },
            field: {
                type: Object
            },
            useCamera:{
                type: Boolean,
                default: false
            },
            parentId:{
                default: null
            },
            showLabel:{
                type: Boolean,
                default: true
            },
            showAttachmentUrl:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                propsVal: this.value,
                isBeingChanged: false,
                showPDF: false
            }
        },
        computed: {
            acceptRule(){
                if(this.field.validation){
                    let accept = this.field.validation;
                    return accept.replace('base64mimetypes:','');
                }
            },
            required: function(){
                if(this.field.validation !== null) {
                    return this.field.validation.includes('required');
                }
                return false;
            },
            attachmentUrl(){
                if(!this.getFieldOptions.includes('/')){
                    return process.env.VUE_APP_API_URL +'/api/files/attachments/' + this.getFieldOptions;
                }
                if(this.getFieldOptions.includes('public')){
                    return process.env.VUE_APP_API_URL + '/' + this.getFieldOptions.replace('public', 'storage');
                }
                return process.env.VUE_APP_API_URL +'/api/files/' + this.getFieldOptions;
            },
            getFieldOptions(){
                if(!Array.isArray(this.field.options)) {
                    return this.field.options;
                }
                if(this.parentId && this.field.options.filter(option => option.id === this.parentId)[0]) {
                    let value = this.field.options.filter(option => option.id === this.parentId)[0].value

                    this.$emit("returnSelectedOption",  value);
                    return   value;
                }
            },
        },
        methods: {
            updateVal(val){
                this.propsVal = val;
            },
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createImage(files[0]);
            },
            createImage(file) {
                var image = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = e => {
                    vm.propsVal = e.target.result;
                    this.$emit("change");
                };
                reader.readAsDataURL(file);
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    this.$emit("input", value);
                },
                deep: true
            },
            value:{
                handler(value) {
                    this.propsVal = value;
                },
                deep: true
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
