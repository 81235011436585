import { render, staticRenderFns } from "./PaginationLinks.vue?vue&type=template&id=baa29ce8&"
import script from "./PaginationLinks.vue?vue&type=script&lang=js&"
export * from "./PaginationLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports