<template>
    <widget-stat type="quotes-mismatch" :mode="mode"></widget-stat>
</template>

<script>

    import WidgetStat from "./WidgetStat";

    export default {
        components: {
            WidgetStat,
        },
        props:{
            mode:{
                type: String,
                default: null
            }
        }
    };
</script>
