<template>
    <dashboard-widget-template title="My Orders">
        <template v-slot:body>
            <pagination class="mt-2" title="Orders" :entities="orders" :showTitleBar="false" bodyBgClass="bg-transparent" tableHeaderBgClass="bg-transparent" :allowOverflow="false">
                <template v-slot:headers>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Reference
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Location</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': size === 'small'}">Progress</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Status</th>
                </template>
                <template v-slot:items>
                    <tr v-for="entity in orders" class="cursor-pointer" @click="navigateToOrder(entity)">
                        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                            <div>{{ entity.entity.properties.get('order_reference') }}
                            </div>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                            {{ entity.entity.properties.get('project') }} - {{ entity.entity.properties.get('site') }}
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': size === 'small'}">
                            <div class="col-span-3">
                                <dd class="text-xs font-normal text-secondary-darkgrey">
                                    <div class="w-full my-1">
                                        <div class="w-full bg-black dark:bg-white bg-opacity-10 rounded-full h-2.5 opacity-100">
                                            <div class="bg-v3-green bg-opacity-80 h-2.5 rounded-full" :style="getStatusWidth(entity.entity.properties)"></div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </td>
                        <td class="whitespace-normal px-3 py-4 text-sm">
                            <div class="flex items-center">
                                <status-badge :status="entity.entity.properties.get('status')" :name="entity.entity.properties.get('status').toLowerCase()"></status-badge>
                            </div>
                        </td>
                    </tr>
                </template>
            </pagination>
        </template>
    </dashboard-widget-template>
</template>

<script>
import DashboardWidgetTemplate from "@/v3/components/dashboardWigets/DashboardWidgetTemplate.vue";
import Siren from "super-siren";
import {mapGetters} from "vuex";
import Pagination from "@/v3/components/pagination/Pagination.vue";
import StatusBadge from "@/v3/components/StatusBadge.vue";

export default {
    components: {StatusBadge, Pagination, DashboardWidgetTemplate},
    props:{
        size:{
            type: String,
            default: null
        },
        orders:{
            type: Object|Array,
            default: null
        }
    },
    methods:{
        getStatusWidth(properties){
            if(this.getStatusValue(properties) > 100){
                return "width: 100%";
            }
            return "width: " + this.getStatusValue(properties) + "%";
        },
        getStatusValue(properties){
            return (properties.get('status_sort')/80) * 100
        },
        navigateToOrder(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first();
            if(selfLink){
                console.log(selfLink.href.split('/api')[1]);
                this.$router.push(selfLink.href.split('/api')[1]);
            }
        }
    }


}
</script>
